// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import { createActionsHook, createStateHook } from '@atlassian/react-sweet-state';
import { Store } from './index.tsx';

export const useSearchKey = createStateHook(Store, { selector: (state) => state.searchKey });

export const useIsFetching = createStateHook(Store, { selector: (state) => state.isFetching });

export const useIsFetchingView = createStateHook(Store, {
	selector: (state) => state.isFetchingView,
});

export const useIsNetworkError = createStateHook(Store, {
	selector: (state) => state.isNetworkError,
});

export const useIsRefreshing = createStateHook(Store, { selector: (state) => state.isRefreshing });

export const useTotalIssueCount = createStateHook(Store, {
	selector: (state) => state.totalIssueCount,
});

export const useTotalIssueCountIsFetching = createStateHook(Store, {
	selector: (state) => state.totalIssueCountIsFetching,
});

export const useTotalIssueCountIsError = createStateHook(Store, {
	selector: (state) => state.totalIssueCountIsError,
});

export const useIssueSearchActions = createActionsHook(Store);
