/** @jsx jsx */
import React, {
	type PointerEventHandler,
	useCallback,
	useRef,
	useState,
	useMemo,
	useEffect,
} from 'react';
import { cssMap, jsx } from '@compiled/react';

import { useUID } from 'react-uid';
import ChevronLeftIcon from '@atlaskit/icon/utility/migration/chevron-left';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import VisuallyHidden from '@atlaskit/visually-hidden';

import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import type { HandlerProps } from '../../common/types.tsx';
import {
	useDoubleClick,
	calculateNewRatio,
	useAnimationFrameThrottle,
	clampValue,
} from '../../common/utils.tsx';
import messages from './messages.tsx';
import { getPercentageWithinPixelBounds } from './utils.tsx';
import { createKeyboardResizeManager } from './keyboard-resize-manager.tsx';

import { ResizeHandle, ResizeBorder, ResizeIcon } from './styled.tsx';

const RESIZE_ICON_TEST_ID = 'flex-resizer.ui.handle.resize-icon';
const RESIZE_CHEVRON_ICON = 'flex-resizer.ui.handle.chevron-';

const grabAreaStyles = cssMap({
	root: {
		/**
		 * The interactive grab area is centered on the container element's border. `17px` is used as the grab area covers `8px` on one side
		 * and `8px` on the other side, plus an extra `1px` to account for the container element's border.
		 */
		width: '15px',
		height: '100%',
		position: 'absolute',
		paddingTop: token('space.0'),
		paddingRight: token('space.0'),
		paddingBottom: token('space.0'),
		paddingLeft: token('space.0'),
		color: 'transparent',
		backgroundColor: 'transparent',
		transitionProperty: 'color',
		transitionDuration: '100ms',
		transitionDelay: '0ms',
		'&:hover': {
			// We are setting the cursor within the :hover pseudo to ensure the specifity is higher than Pressable's cursor.
			cursor: 'ew-resize',
			transitionDelay: '200ms',
		},
		'&:hover, &:focus-within': {
			color: token('color.border.focused'),
			transitionProperty: 'color',
			transitionDuration: '200ms',
		},
		// We are using the `:active` state to update the line color when dragging, instead of using state. This works as we aren't using
		// drag previews and instead are moving and styling the dragged element. There were also issues with the Compiled styles in test environments.
		'&:active': {
			color: token('color.border.focused'),
			// Removing the color transition so we instantly change from hovered to dragged colors.
			transition: 'none',
		},
	},
});

const lineStyles = cssMap({
	root: {
		position: 'absolute',
		display: 'block',
		width: '2px',
		height: '100%',
		color: 'inherit',
		backgroundColor: 'currentcolor',
		// 7px is used instead of 8px to account for the container element's border and ensure the line remains visually centered.
		insetInlineStart: '6.5px',
	},
});

const Resizer = ({
	childrenContainerRef,
	initialRatio,
	defaultRatio,
	minWidth,
	minRatio = 0,
	maxWidth,
	maxRatio = 1,
	extraWidth = 0,
	position = 'left',
	resizeHandleAsOverlay,
	resizeHandleZIndex,
	onRatioChange,
	onRatioReset,
	onResizeStart,
	chevronDirection,
	persistResizeIcon,
	onResizeHandleClick,
	collapsed,
	minWidthLeftContainer,
}: HandlerProps) => {
	const startX = useRef(0);
	const startWidth = useRef(0);
	const parentWidth = useRef(0);

	const onResizeStartStableRef = useRef(onResizeStart);
	const onResizeEndStableRef = useRef(onRatioChange);
	const [rangeInputValue, setRangeInputValue] = useState(maxRatio - (initialRatio ?? defaultRatio));

	const [inputRange, setInputRange] = useState({
		min: 0,
		max: 1,
	});
	/*
      onRatioChange calls setFlexBasisProp from the parent component. Even if the slider value changes to 0 there are
	  chances the line splitter can min go upto 0.2. If, user performs keyboard navigation the user will not see any movement
	  if the value was 0 unless it reaches to 0.2 lets say

	  To avoid this, lineSplitterRef.current.getBoundingClientRect -> returns width giving the exact position of the line and we
	  can thus update the ratio which we are doing on handleSliderFocus
	*/
	const lineSplitterRef = useRef<HTMLDivElement>(null);
	const [keyboardResizeManager] = useState(() =>
		createKeyboardResizeManager({
			onResizeStart: () => onResizeStartStableRef.current?.(),
			onResizeEnd: (...args) => {
				return onResizeEndStableRef.current?.(...args);
			},
		}),
	);

	const prevChevronDirection = usePreviousWithInitial(chevronDirection);
	const nextRatio = useRef(initialRatio ?? defaultRatio);
	const styleElement = useRef<HTMLStyleElement | null>(null);
	const labelId = useUID();
	const { formatMessage } = useIntl();

	useEffect(() => {
		/*
		initialRatio is 0 means Panel splitter is completely closed
        When it is collapsed and initialRatio is 0, next time when we expand,
		range should take defaultRatio

        Else

        If initialRatio is > 0, when we collapse the panel and expand, range should take initialRatio.
        */
		if (collapsed && fg('jira_a11y_pannel_splitter_navigations')) {
			setRangeInputValue(
				maxRatio - (initialRatio && initialRatio !== 0 ? initialRatio : defaultRatio),
			);
		}
	}, [collapsed, initialRatio, defaultRatio, maxRatio]);

	const setFlexBasisProp = useCallback(
		(ratio: number, setNextRatio?: number) => {
			// This will force-fix any in consitencies in the ratio value if ratio is set from parent components
			if (setNextRatio !== undefined) {
				nextRatio.current = setNextRatio;
			}
			if (childrenContainerRef.current) {
				childrenContainerRef.current.style.setProperty(
					'flex-basis',
					extraWidth > 0 ? `calc(${ratio * 100}% + ${extraWidth}px)` : `${ratio * 100}%`,
				);
			}
		},
		[childrenContainerRef, extraWidth],
	);

	const resetDetector = useDoubleClick(
		useCallback(() => {
			const targetRatio =
				clampValue({
					value: defaultRatio * parentWidth.current,
					minValue: Math.max(minWidth ?? 0, minRatio * parentWidth.current),
					maxValue: Math.min(maxWidth ?? Infinity, maxRatio * parentWidth.current),
				}) / parentWidth.current;

			nextRatio.current = targetRatio;
			setFlexBasisProp(targetRatio);
			onRatioReset?.(targetRatio);
		}, [defaultRatio, minWidth, minRatio, maxWidth, maxRatio, setFlexBasisProp, onRatioReset]),
	);

	const [onPointerMoveThrottled, cancelPointerMove] = useAnimationFrameThrottle(
		useCallback(
			(e: PointerEvent) => {
				const r = calculateNewRatio({
					parentWidth: parentWidth.current,
					currentWidth: startWidth.current,
					deltaX: startX.current - e.pageX,
					minWidth: minWidth ?? 0,
					minRatio,
					maxWidth: maxWidth ?? Infinity,
					maxRatio,
					baseWidth: extraWidth,
					position,
				});
				nextRatio.current = r;
				setFlexBasisProp(r);
			},
			[extraWidth, maxRatio, maxWidth, minRatio, minWidth, position, setFlexBasisProp],
		),
	);

	const onPointerMove = useCallback(
		(e: PointerEvent) => {
			e.preventDefault();
			onPointerMoveThrottled(e);
		},
		[onPointerMoveThrottled],
	);

	const onPointerUp = useCallback(() => {
		const r = nextRatio.current;
		const resetTriggered = resetDetector();
		if (!resetTriggered) {
			onRatioChange?.(r, nextRatio.current * parentWidth.current, setFlexBasisProp);
		}

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener('pointermove', onPointerMove, { capture: true });

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener('pointerup', onPointerUp, { capture: true });
		const stylesheet = styleElement.current?.sheet;
		if (stylesheet) {
			stylesheet.deleteRule(1);
			stylesheet.deleteRule(0);
		}
		cancelPointerMove();
		if (fg('jira_a11y_pannel_splitter_navigations')) setRangeInputValue(maxRatio - r);
	}, [cancelPointerMove, onPointerMove, onRatioChange, resetDetector, setFlexBasisProp, maxRatio]);

	const onPointerDown = useCallback<PointerEventHandler<HTMLDivElement>>(
		(e) => {
			// Check if the chevron icon was clicked and prevent dragging
			if (onResizeHandleClick && e.target instanceof HTMLElement) {
				const target =
					(e.target.getAttribute('data-testid') || '').indexOf(RESIZE_CHEVRON_ICON) > -1
						? 'chevron'
						: 'resizer';
				if (!onResizeHandleClick?.(target, setFlexBasisProp, e)) {
					e.preventDefault();
					return;
				}
			}
			// Prevent resize when the resizer is collapsed
			if (collapsed) return;
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener('pointermove', onPointerMove, { capture: true });

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener('pointerup', onPointerUp, { capture: true });

			const stylesheet = styleElement.current?.sheet;
			if (stylesheet) {
				// There is a chromium bug which forces all scrollbars to be visible when 'pointer-events: none' is added.
				// Chromium bug: https://bugs.chromium.org/p/chromium/issues/detail?id=1280013&q=pointer-events%20scrollbar&can=2
				// The previously implemented workarounds for this issue didn't solve all use cases, i.e., horizontal scrollbars,
				// thus, 'user-select: none' is used to prevent text-highlighting but allows hover events, unlike 'pointer-events'.
				stylesheet.insertRule('body { user-select: none; }', 0);

				// Set the dragging cursor (higher than disabled pointer events)
				stylesheet.insertRule('#jira { cursor: ew-resize; }', 1);
			}

			const currentContainer = childrenContainerRef.current;
			const parentContainer = currentContainer?.parentElement;
			if (!currentContainer || !parentContainer) return;

			startWidth.current = currentContainer.getBoundingClientRect().width;
			parentWidth.current = parentContainer.getBoundingClientRect().width ?? 0;

			onResizeStart?.();

			startX.current = e.pageX;

			// This is preventing popups from closing when the resize handle is dragged
			if (!onResizeHandleClick) e.preventDefault();
		},
		[
			onPointerMove,
			onPointerUp,
			childrenContainerRef,
			onResizeStart,
			onResizeHandleClick,
			collapsed,
			setFlexBasisProp,
		],
	);

	const handleSliderFocus = useCallback(() => {
		const stylesheet = styleElement.current?.sheet;
		if (stylesheet) {
			// There is a chromium bug which forces all scrollbars to be visible when 'pointer-events: none' is added.
			// Chromium bug: https://bugs.chromium.org/p/chromium/issues/detail?id=1280013&q=pointer-events%20scrollbar&can=2
			// The previously implemented workarounds for this issue didn't solve all use cases, i.e., horizontal scrollbars,
			// thus, 'user-select: none' is used to prevent text-highlighting but allows hover events, unlike 'pointer-events'.
			stylesheet.insertRule('body { user-select: none; }', 0);

			// Set the dragging cursor (higher than disabled pointer events)
			stylesheet.insertRule('#jira { cursor: ew-resize; }', 1);
		}

		const currentContainer = childrenContainerRef.current;
		const parentContainer = currentContainer?.parentElement;
		if (!currentContainer || !parentContainer) return;

		startWidth.current = currentContainer.getBoundingClientRect().width;
		parentWidth.current = parentContainer.getBoundingClientRect().width ?? 0;

		/*
        calculate the range only when the user actually wants navigate via
        keyboard and save the computation at initial render
        */

		setInputRange((prev) => ({
			...prev,
			min: minWidthLeftContainer ? minWidthLeftContainer / parentWidth.current : minRatio,
			max: maxRatio,
		}));

		onResizeStart?.();
	}, [onResizeStart, childrenContainerRef, maxRatio, minRatio, minWidthLeftContainer]);

	const handleSliderInputChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const value = parseFloat(e.target.value);
			setRangeInputValue(value);
			// doing this maxRatio - value because current drag has left as 100% and right as 0% alternative to input range

			nextRatio.current = maxRatio - value;
			setFlexBasisProp(maxRatio - value);

			keyboardResizeManager.onResize({
				ratio: maxRatio - value,
				width: (maxRatio - value) * parentWidth.current,
				flexBasis: setFlexBasisProp,
			});
		},
		[setFlexBasisProp, maxRatio, keyboardResizeManager],
	);

	const ariaValueText = useMemo(
		() =>
			`${getPercentageWithinPixelBounds({ currentWidth: rangeInputValue, minRatio, maxRatio })}% width`,
		[rangeInputValue, minRatio, maxRatio],
	);

	const ResizeBar = useCallback(() => {
		const directionDidChange = chevronDirection !== prevChevronDirection;

		return (
			<ResizeHandle
				tabIndex={0}
				role="separator"
				aria-orientation="vertical"
				data-testid="flex-resizer.ui.handle.resizer"
				onPointerDown={onPointerDown}
				position={position}
				resizeHandleZIndex={resizeHandleZIndex}
				resizeHandleAsOverlay={resizeHandleAsOverlay}
				aria-valuenow={Math.floor(nextRatio.current * 100)}
				collapsed={collapsed}
			>
				<ResizeBorder triggerAnimation={directionDidChange} collapsed={collapsed}>
					<ResizeIcon
						triggerAnimation={directionDidChange}
						data-testid={RESIZE_ICON_TEST_ID}
						persistResizeIcon={persistResizeIcon}
						onResizeHandleClick={onResizeHandleClick}
					>
						{chevronDirection === 'LEFT' && (
							<ChevronLeftIcon
								LEGACY_size="medium"
								spacing="spacious"
								label=""
								testId={`${RESIZE_CHEVRON_ICON}left`}
							/>
						)}

						{chevronDirection === 'RIGHT' && (
							<ChevronRightIcon
								LEGACY_size="medium"
								spacing="spacious"
								label=""
								testId={`${RESIZE_CHEVRON_ICON}right`}
							/>
						)}
					</ResizeIcon>
				</ResizeBorder>
			</ResizeHandle>
		);
	}, [
		onPointerDown,
		position,
		resizeHandleZIndex,
		resizeHandleAsOverlay,
		collapsed,
		chevronDirection,
		prevChevronDirection,
		persistResizeIcon,
		onResizeHandleClick,
	]);

	const renderKeyboardNavigation = useCallback(() => {
		return (
			<div css={grabAreaStyles.root} ref={lineSplitterRef}>
				<VisuallyHidden>
					<input
						id={labelId}
						type="range"
						value={rangeInputValue}
						step="0.01"
						min={inputRange.min}
						max={inputRange.max}
						aria-valuetext={ariaValueText}
						onChange={handleSliderInputChange}
						onFocus={handleSliderFocus}
					/>
					<label htmlFor={labelId}>{formatMessage(messages.resizer)}</label>
				</VisuallyHidden>
				<span css={lineStyles.root} />
			</div>
		);
	}, [
		ariaValueText,
		formatMessage,
		handleSliderFocus,
		handleSliderInputChange,
		labelId,
		rangeInputValue,
		inputRange.max,
		inputRange.min,
	]);

	const renderResizeBar = useCallback(() => {
		return (
			<ResizeHandle
				data-testid="flex-resizer.ui.handle.resizer"
				onPointerDown={onPointerDown}
				position={position}
				resizeHandleZIndex={resizeHandleZIndex}
				resizeHandleAsOverlay={resizeHandleAsOverlay}
				collapsed={collapsed}
			>
				{renderKeyboardNavigation()}
				<ResizeBorder
					triggerAnimation={chevronDirection !== prevChevronDirection}
					collapsed={collapsed}
				>
					<ResizeIcon
						triggerAnimation={chevronDirection !== prevChevronDirection}
						data-testid={RESIZE_ICON_TEST_ID}
						persistResizeIcon={persistResizeIcon}
						onResizeHandleClick={onResizeHandleClick}
					>
						{chevronDirection === 'LEFT' && (
							<ChevronLeftIcon
								LEGACY_size="medium"
								spacing="spacious"
								label=""
								testId={`${RESIZE_CHEVRON_ICON}left`}
							/>
						)}

						{chevronDirection === 'RIGHT' && (
							<ChevronRightIcon
								LEGACY_size="medium"
								spacing="spacious"
								label=""
								testId={`${RESIZE_CHEVRON_ICON}right`}
							/>
						)}
					</ResizeIcon>
				</ResizeBorder>
			</ResizeHandle>
		);
	}, [
		onPointerDown,
		position,
		resizeHandleZIndex,
		resizeHandleAsOverlay,
		persistResizeIcon,
		onResizeHandleClick,
		collapsed,
		renderKeyboardNavigation,
		chevronDirection,
		prevChevronDirection,
	]);

	return (
		<>
			{fg('jira_a11y_pannel_splitter_navigations') ? renderResizeBar() : <ResizeBar />}
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766 */}
			<style ref={styleElement} />
		</>
	);
};
export default Resizer;
