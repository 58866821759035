/**
 * @generated SignedSource<<4ab069ce0ad7ab7212c162ffd38bd75e>>
 * @relayHash 0c7abbbb86443afaf9096cff5642a39d
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2202f78aa9fe773b5bc17ec12a057a6eb6f9fe75f26f53eecca1f6eb03658a9c

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraIssueSearchOperationScope = "NIN_GLOBAL" | "NIN_GLOBAL_SCHEMA_REFACTOR" | "NIN_GLOBAL_SHADOW_REQUEST" | "NIN_PROJECT" | "NIN_PROJECT_SCHEMA_REFACTOR" | "NIN_PROJECT_SHADOW_REQUEST" | "%future added value";
export type JiraSoftwareIssueSearchCustomInputContext = "BACKLOG" | "BOARD" | "NONE" | "%future added value";
export type JiraIssueSearchViewFieldSetsContext = {
  issueContext?: JiraIssueSearchViewFieldSetsIssueContext | null | undefined;
  projectContext?: JiraIssueSearchViewFieldSetsProjectContext | null | undefined;
};
export type JiraIssueSearchViewFieldSetsIssueContext = {
  issueKey?: string | null | undefined;
};
export type JiraIssueSearchViewFieldSetsProjectContext = {
  issueType?: string | null | undefined;
  project?: string | null | undefined;
};
export type JiraIssueSearchFieldSetsInput = {
  fieldSetIds?: ReadonlyArray<string> | null | undefined;
  viewInput?: JiraIssueSearchViewInput | null | undefined;
};
export type JiraIssueSearchViewInput = {
  context?: JiraIssueSearchViewFieldSetsContext | null | undefined;
  filterId?: string | null | undefined;
  namespace?: string | null | undefined;
  viewId?: string | null | undefined;
};
export type JiraIssueSearchInput = {
  childIssuesInput?: JiraIssueSearchChildIssuesInput | null | undefined;
  customInput?: JiraIssueSearchCustomInput | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
};
export type JiraIssueSearchChildIssuesInput = {
  filterByProjectKeys?: ReadonlyArray<string> | null | undefined;
  filterId?: string | null | undefined;
  jql?: string | null | undefined;
  namespace?: string | null | undefined;
  parentIssueKey: string;
  viewId?: string | null | undefined;
};
export type JiraIssueSearchCustomInput = {
  jiraSoftwareInput?: JiraSoftwareIssueSearchCustomInput | null | undefined;
};
export type JiraSoftwareIssueSearchCustomInput = {
  additionalJql?: string | null | undefined;
  context?: JiraSoftwareIssueSearchCustomInputContext | null | undefined;
  jiraEntityId: string;
};
export type JiraIssueSearchOptions = {
  issueKey?: string | null | undefined;
};
export type JiraIssueSearchScope = {
  operationScope?: JiraIssueSearchOperationScope | null | undefined;
};
export type JiraIssueSearchViewConfigInput = {
  staticViewInput?: JiraIssueSearchStaticViewInput | null | undefined;
  viewInput?: JiraIssueSearchViewInput | null | undefined;
};
export type JiraIssueSearchStaticViewInput = {
  isGroupingEnabled?: boolean | null | undefined;
  isHideDoneEnabled?: boolean | null | undefined;
  isHierarchyEnabled?: boolean | null | undefined;
};
export type IssueNavigatorIssueSearchPaginationQuery$variables = {
  after?: string | null | undefined;
  amountOfColumns?: number | null | undefined;
  before?: string | null | undefined;
  cloudId: string;
  fieldSetIds: ReadonlyArray<string>;
  fieldSetsContext?: JiraIssueSearchViewFieldSetsContext | null | undefined;
  fieldSetsInput?: JiraIssueSearchFieldSetsInput | null | undefined;
  filterId?: string | null | undefined;
  first?: number | null | undefined;
  isRefactorNinToViewSchemaEnabled: boolean;
  issueSearchInput: JiraIssueSearchInput;
  last?: number | null | undefined;
  namespace?: string | null | undefined;
  options?: JiraIssueSearchOptions | null | undefined;
  projectKey?: string | null | undefined;
  projectKeys?: ReadonlyArray<string> | null | undefined;
  scope?: JiraIssueSearchScope | null | undefined;
  shouldQueryFieldSetsById: boolean;
  shouldQueryHasChildren: boolean;
  viewConfigInput?: JiraIssueSearchViewConfigInput | null | undefined;
  viewId?: string | null | undefined;
};
export type IssueNavigatorIssueSearchPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"main_issueNavigator_IssueNavigator_issueSearchData">;
};
export type IssueNavigatorIssueSearchPaginationQuery = {
  response: IssueNavigatorIssueSearchPaginationQuery$data;
  variables: IssueNavigatorIssueSearchPaginationQuery$variables;
};
({
  "isRefactorNinToViewSchemaEnabled": isRefactorNinToViewSchemaEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider": isRefactorNinToViewSchemaEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider": bulkOpsInNinEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider": isReparentingEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider": isJscM2Enabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
  "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider: {
    readonly get: () => boolean;
  };
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider: {
    readonly get: () => boolean;
  };
  readonly isRefactorNinToViewSchemaEnabled: {
    readonly get: () => boolean;
  };
});

import bulkOpsInNinEnabled_provider from '@atlassian/jira-relay-provider/src/is-bulk-ops-in-nin-enabled.relayprovider';
import isJscInfiniteScrollEnabled_provider from '@atlassian/jira-relay-provider/src/is-jsc-infinite-scroll-enabled.relayprovider';
import isRefactorNinToViewSchemaEnabled_provider from '@atlassian/jira-relay-provider/src/is-refactor-nin-to-view-schema-enabled.relayprovider';
import isReparentingEnabled_provider from '@atlassian/jira-relay-provider/src/jira-list-reparenting.relayprovider';
import isJscInlineEditRefactorEnabled_provider from '@atlassian/jira-relay-provider/src/jsc-inline-editing-field-refactor.relayprovider';
import isJscM2Enabled_provider from '@atlassian/jira-relay-provider/src/jsc-m2-fe-changes.relayprovider';
import isNikeChinStoryPointsInlineEditEnabled_provider from '@atlassian/jira-relay-provider/src/nike-chin-story-points-inline-edit.relayprovider';
import includeSingleSelectColorField_provider from '@atlassian/jira-relay-provider/src/thor-colorful-single-select-milestone2-experiment.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amountOfColumns"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetIds"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetsContext"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldSetsInput"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterId"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isRefactorNinToViewSchemaEnabled"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueSearchInput"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "namespace"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "options"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v15 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKeys"
},
v16 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scope"
},
v17 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldQueryFieldSetsById"
},
v18 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "shouldQueryHasChildren"
},
v19 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewConfigInput"
},
v20 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v21 = {
  "kind": "Variable",
  "name": "projectKey",
  "variableName": "projectKey"
},
v22 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "fieldSetsInput",
    "variableName": "fieldSetsInput"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "issueSearchInput",
    "variableName": "issueSearchInput"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "options",
    "variableName": "options"
  },
  {
    "kind": "Variable",
    "name": "scope",
    "variableName": "scope"
  },
  {
    "kind": "Variable",
    "name": "viewConfigInput",
    "variableName": "viewConfigInput"
  }
],
v23 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v24 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v25 = {
  "kind": "ScalarField",
  "name": "id"
},
v26 = {
  "kind": "ScalarField",
  "name": "key"
},
v27 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v28 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v29 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v30 = {
  "concreteType": "JiraStatusCategory",
  "kind": "LinkedField",
  "name": "statusCategory",
  "plural": false,
  "selections": [
    (v29/*: any*/),
    (v25/*: any*/)
  ]
},
v31 = {
  "kind": "InlineFragment",
  "selections": [
    (v28/*: any*/),
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        (v30/*: any*/),
        (v25/*: any*/)
      ]
    }
  ],
  "type": "JiraStatusField"
},
v32 = {
  "concreteType": "JiraIssueTypeHierarchyLevel",
  "kind": "LinkedField",
  "name": "hierarchy",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "level"
    }
  ]
},
v33 = {
  "kind": "ScalarField",
  "name": "__id"
},
v34 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "amountOfColumns"
},
v35 = [
  {
    "kind": "Variable",
    "name": "context",
    "variableName": "fieldSetsContext"
  },
  {
    "kind": "Variable",
    "name": "filterId",
    "variableName": "filterId"
  },
  (v34/*: any*/),
  {
    "kind": "Variable",
    "name": "namespace",
    "variableName": "namespace"
  },
  {
    "kind": "Variable",
    "name": "viewId",
    "variableName": "viewId"
  }
],
v36 = {
  "kind": "ScalarField",
  "name": "text"
},
v37 = {
  "kind": "ScalarField",
  "name": "name"
},
v38 = {
  "kind": "ScalarField",
  "name": "picture"
},
v39 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "small"
    }
  ]
},
v40 = {
  "kind": "ScalarField",
  "name": "type"
},
v41 = {
  "kind": "ScalarField",
  "name": "fieldSetId"
},
v42 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isJiraIssueField"
},
v43 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraAffectedServiceConnection",
      "kind": "LinkedField",
      "name": "selectedAffectedServicesConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraAffectedServiceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraAffectedService",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "serviceId"
                },
                (v37/*: any*/),
                (v25/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraAffectedServicesField"
},
v44 = {
  "kind": "ScalarField",
  "name": "value"
},
v45 = [
  (v44/*: any*/),
  (v25/*: any*/),
  {
    "kind": "ScalarField",
    "name": "isDisabled"
  }
],
v46 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "lazyIsEditableInIssueView"
    }
  ]
},
v47 = {
  "kind": "InlineFragment",
  "selections": [
    (v37/*: any*/),
    {
      "concreteType": "JiraCascadingOption",
      "kind": "LinkedField",
      "name": "cascadingOption",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraOption",
          "kind": "LinkedField",
          "name": "parentOptionValue",
          "plural": false,
          "selections": (v45/*: any*/)
        },
        {
          "concreteType": "JiraOption",
          "kind": "LinkedField",
          "name": "childOptionValue",
          "plural": false,
          "selections": (v45/*: any*/)
        }
      ]
    },
    (v46/*: any*/)
  ],
  "type": "JiraCascadingSelectField"
},
v48 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  }
],
v49 = {
  "alias": "value",
  "kind": "ScalarField",
  "name": "id"
},
v50 = {
  "alias": "label",
  "kind": "ScalarField",
  "name": "name"
},
v51 = {
  "kind": "InlineFragment",
  "selections": [
    (v37/*: any*/),
    {
      "args": (v48/*: any*/),
      "concreteType": "JiraComponentConnection",
      "kind": "LinkedField",
      "name": "selectedComponentsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraComponentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraComponent",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v49/*: any*/),
                (v50/*: any*/),
                (v25/*: any*/)
              ]
            }
          ]
        }
      ],
      "storageKey": "selectedComponentsConnection(first:1000)"
    },
    (v46/*: any*/)
  ],
  "type": "JiraComponentsField"
},
v52 = {
  "kind": "ScalarField",
  "name": "dateTime"
},
v53 = {
  "kind": "InlineFragment",
  "selections": [
    (v52/*: any*/),
    (v37/*: any*/),
    (v46/*: any*/)
  ],
  "type": "JiraDateTimePickerField"
},
v54 = {
  "kind": "InlineFragment",
  "selections": [
    (v36/*: any*/),
    (v37/*: any*/),
    (v46/*: any*/)
  ],
  "type": "JiraSingleLineTextField"
},
v55 = {
  "kind": "ScalarField",
  "name": "totalCount"
},
v56 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraCommentSummary",
      "kind": "LinkedField",
      "name": "commentSummary",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "canAddComment"
        },
        (v55/*: any*/)
      ]
    }
  ],
  "type": "JiraCommentSummaryField"
},
v57 = {
  "kind": "InlineFragment",
  "selections": [
    (v37/*: any*/),
    {
      "kind": "ScalarField",
      "name": "uri"
    },
    (v46/*: any*/)
  ],
  "type": "JiraUrlField"
},
v58 = {
  "concreteType": "JiraColor",
  "kind": "LinkedField",
  "name": "color",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "colorKey"
    },
    (v25/*: any*/)
  ]
},
v59 = [
  (v58/*: any*/)
],
v60 = {
  "kind": "ScalarField",
  "name": "optionId"
},
v61 = {
  "kind": "InlineFragment",
  "selections": [
    (v37/*: any*/),
    {
      "concreteType": "JiraOptionConnection",
      "kind": "LinkedField",
      "name": "fieldOptions",
      "plural": false,
      "selections": [
        (v55/*: any*/)
      ]
    },
    {
      "concreteType": "JiraOption",
      "kind": "LinkedField",
      "name": "fieldOption",
      "plural": false,
      "selections": [
        (v44/*: any*/),
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": (v59/*: any*/)
        },
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": (v59/*: any*/)
        },
        (v25/*: any*/),
        (v60/*: any*/)
      ]
    },
    (v46/*: any*/)
  ],
  "type": "JiraSingleSelectField"
},
v62 = {
  "kind": "InlineFragment",
  "selections": [
    (v37/*: any*/),
    {
      "concreteType": "JiraOptionConnection",
      "kind": "LinkedField",
      "name": "selectedOptions",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraOptionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraOption",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v25/*: any*/),
                (v44/*: any*/),
                (v60/*: any*/)
              ]
            }
          ]
        }
      ]
    },
    (v46/*: any*/)
  ],
  "type": "JiraMultipleSelectField"
},
v63 = {
  "kind": "InlineFragment",
  "selections": [
    (v37/*: any*/),
    {
      "concreteType": "JiraOptionConnection",
      "kind": "LinkedField",
      "name": "selectedOptions",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraOptionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraOption",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v25/*: any*/),
                (v44/*: any*/)
              ]
            }
          ]
        }
      ]
    },
    (v46/*: any*/)
  ],
  "type": "JiraCheckboxesField"
},
v64 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v65 = {
  "kind": "ScalarField",
  "name": "accountStatus"
},
v66 = {
  "kind": "InlineFragment",
  "selections": [
    (v37/*: any*/),
    {
      "concreteType": "JiraUserConnection",
      "kind": "LinkedField",
      "name": "selectedUsersConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraUserEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v24/*: any*/),
                (v64/*: any*/),
                (v37/*: any*/),
                (v38/*: any*/),
                (v25/*: any*/),
                (v65/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "email"
                    }
                  ],
                  "type": "AtlassianAccountUser"
                }
              ]
            }
          ]
        }
      ]
    },
    (v46/*: any*/)
  ],
  "type": "JiraMultipleSelectUserPickerField"
},
v67 = {
  "kind": "InlineFragment",
  "selections": [
    (v37/*: any*/),
    {
      "concreteType": "JiraTeamView",
      "kind": "LinkedField",
      "name": "selectedTeam",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "jiraSuppliedId"
        },
        {
          "kind": "ScalarField",
          "name": "jiraSuppliedName"
        }
      ]
    },
    (v46/*: any*/)
  ],
  "type": "JiraTeamViewField"
},
v68 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "issue",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraTimeTrackingField",
          "kind": "LinkedField",
          "name": "timeTrackingField",
          "plural": false,
          "selections": [
            (v25/*: any*/),
            {
              "concreteType": "JiraEstimate",
              "kind": "LinkedField",
              "name": "originalEstimate",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "timeInSeconds"
                }
              ]
            },
            {
              "concreteType": "JiraTimeTrackingSettings",
              "kind": "LinkedField",
              "name": "timeTrackingSettings",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "workingHoursPerDay"
                },
                {
                  "kind": "ScalarField",
                  "name": "workingDaysPerWeek"
                },
                {
                  "kind": "ScalarField",
                  "name": "defaultFormat"
                },
                {
                  "kind": "ScalarField",
                  "name": "defaultUnit"
                }
              ]
            },
            (v28/*: any*/),
            (v37/*: any*/),
            (v40/*: any*/),
            (v46/*: any*/)
          ]
        },
        (v25/*: any*/)
      ]
    }
  ],
  "type": "JiraOriginalTimeEstimateField"
},
v69 = {
  "kind": "InlineFragment",
  "selections": [
    (v37/*: any*/),
    {
      "concreteType": "JiraOption",
      "kind": "LinkedField",
      "name": "selectedOption",
      "plural": false,
      "selections": [
        {
          "alias": "label",
          "kind": "ScalarField",
          "name": "value"
        },
        (v25/*: any*/),
        (v49/*: any*/)
      ]
    },
    (v46/*: any*/)
  ],
  "type": "JiraRadioSelectField"
},
v70 = {
  "kind": "InlineFragment",
  "selections": [
    (v37/*: any*/),
    {
      "args": (v48/*: any*/),
      "concreteType": "JiraVersionConnection",
      "kind": "LinkedField",
      "name": "selectedVersionsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraVersionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraVersion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v25/*: any*/),
                (v37/*: any*/),
                (v49/*: any*/),
                (v50/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "versionId"
                }
              ]
            }
          ]
        }
      ],
      "storageKey": "selectedVersionsConnection(first:1000)"
    },
    (v46/*: any*/)
  ],
  "type": "JiraMultipleVersionPickerField"
},
v71 = {
  "kind": "ScalarField",
  "name": "message"
},
v72 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraCmdbObjectConnection",
      "kind": "LinkedField",
      "name": "selectedCmdbObjectsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraCmdbObjectEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraCmdbObject",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "objectId"
                },
                {
                  "kind": "ScalarField",
                  "name": "label"
                },
                {
                  "concreteType": "JiraCmdbAvatar",
                  "kind": "LinkedField",
                  "name": "avatar",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraCmdbMediaClientConfig",
                      "kind": "LinkedField",
                      "name": "mediaClientConfig",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "clientId"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "fileId"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "mediaBaseUrl"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "mediaJwtToken"
                        }
                      ]
                    },
                    {
                      "kind": "ScalarField",
                      "name": "url48"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "concreteType": "QueryError",
          "kind": "LinkedField",
          "name": "errors",
          "plural": true,
          "selections": [
            (v71/*: any*/)
          ]
        }
      ]
    }
  ],
  "type": "JiraCMDBField"
},
v73 = [
  (v37/*: any*/),
  (v25/*: any*/)
],
v74 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": "componentsOld",
      "concreteType": "JiraComponentConnection",
      "kind": "LinkedField",
      "name": "selectedComponentsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraComponentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraComponent",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v73/*: any*/)
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraComponentsField"
},
v75 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isRequired"
    }
  ]
},
v76 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "date"
    },
    (v37/*: any*/),
    (v75/*: any*/),
    (v46/*: any*/)
  ],
  "type": "JiraDatePickerField"
},
v77 = {
  "kind": "InlineFragment",
  "selections": [
    (v52/*: any*/)
  ],
  "type": "JiraDateTimePickerField"
},
v78 = {
  "kind": "ScalarField",
  "name": "summary"
},
v79 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraEpic",
      "kind": "LinkedField",
      "name": "epic",
      "plural": false,
      "selections": [
        (v26/*: any*/),
        (v78/*: any*/),
        {
          "kind": "ScalarField",
          "name": "color"
        },
        (v25/*: any*/)
      ]
    }
  ],
  "type": "JiraEpicLinkField"
},
v80 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "renderedFieldHtml"
    }
  ],
  "type": "JiraFallbackField"
},
v81 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraStatus",
      "kind": "LinkedField",
      "name": "status",
      "plural": false,
      "selections": [
        (v37/*: any*/),
        {
          "kind": "ScalarField",
          "name": "statusId"
        },
        (v30/*: any*/),
        (v25/*: any*/)
      ]
    }
  ],
  "type": "JiraStatusField"
},
v82 = [
  (v37/*: any*/)
],
v83 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "args": (v48/*: any*/),
      "concreteType": "JiraLabelConnection",
      "kind": "LinkedField",
      "name": "selectedLabelsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraLabelEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraLabel",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v82/*: any*/)
            }
          ]
        },
        (v55/*: any*/)
      ],
      "storageKey": "selectedLabelsConnection(first:1000)"
    },
    (v37/*: any*/),
    (v46/*: any*/)
  ],
  "type": "JiraLabelsField"
},
v84 = {
  "concreteType": "JiraSingleLineTextField",
  "kind": "LinkedField",
  "name": "summaryField",
  "plural": false,
  "selections": [
    (v36/*: any*/),
    (v25/*: any*/)
  ]
},
v85 = {
  "concreteType": "JiraColorField",
  "kind": "LinkedField",
  "name": "issueColorField",
  "plural": false,
  "selections": [
    (v58/*: any*/),
    (v25/*: any*/)
  ]
},
v86 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v87 = {
  "concreteType": "JiraIssueTypeField",
  "kind": "LinkedField",
  "name": "issueTypeField",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        (v86/*: any*/),
        (v37/*: any*/),
        (v25/*: any*/)
      ]
    },
    (v25/*: any*/)
  ]
},
v88 = {
  "kind": "ScalarField",
  "name": "webUrl"
},
v89 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "parentIssue",
      "plural": false,
      "selections": [
        (v26/*: any*/),
        (v84/*: any*/),
        (v25/*: any*/)
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "parentIssue",
          "plural": false,
          "selections": [
            (v26/*: any*/),
            (v85/*: any*/),
            (v87/*: any*/),
            (v84/*: any*/),
            (v25/*: any*/)
          ]
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "parentIssue",
          "plural": false,
          "selections": [
            (v26/*: any*/),
            (v85/*: any*/),
            (v87/*: any*/),
            (v84/*: any*/),
            (v25/*: any*/),
            (v27/*: any*/),
            (v88/*: any*/),
            {
              "args": [
                {
                  "kind": "Literal",
                  "name": "ids",
                  "value": [
                    "summary",
                    "issuetype",
                    "status"
                  ]
                }
              ],
              "concreteType": "JiraIssueFieldConnection",
              "kind": "LinkedField",
              "name": "fieldsById",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssueFieldEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v24/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v28/*: any*/),
                            (v36/*: any*/)
                          ],
                          "type": "JiraSingleLineTextField"
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v28/*: any*/),
                            {
                              "concreteType": "JiraIssueType",
                              "kind": "LinkedField",
                              "name": "issueType",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "name": "issueTypeId"
                                },
                                (v37/*: any*/),
                                (v86/*: any*/),
                                (v32/*: any*/),
                                (v25/*: any*/)
                              ]
                            }
                          ],
                          "type": "JiraIssueTypeField"
                        },
                        (v31/*: any*/),
                        (v25/*: any*/)
                      ]
                    }
                  ]
                }
              ],
              "storageKey": "fieldsById(ids:[\"summary\",\"issuetype\",\"status\"])"
            }
          ]
        },
        (v37/*: any*/),
        (v46/*: any*/)
      ]
    }
  ],
  "type": "JiraParentIssueField"
},
v90 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraPriority",
      "kind": "LinkedField",
      "name": "priority",
      "plural": false,
      "selections": [
        (v37/*: any*/),
        {
          "kind": "ScalarField",
          "name": "iconUrl"
        },
        (v25/*: any*/)
      ]
    },
    (v37/*: any*/),
    (v46/*: any*/)
  ],
  "type": "JiraPriorityField"
},
v91 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v37/*: any*/),
        (v26/*: any*/),
        {
          "concreteType": "JiraAvatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "large"
            }
          ]
        },
        (v25/*: any*/)
      ]
    }
  ],
  "type": "JiraProjectField"
},
v92 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraResolution",
      "kind": "LinkedField",
      "name": "resolution",
      "plural": false,
      "selections": (v73/*: any*/)
    }
  ],
  "type": "JiraResolutionField"
},
v93 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraSprintConnection",
      "kind": "LinkedField",
      "name": "selectedSprintsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraSprintEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraSprint",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v25/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "sprintId"
                },
                (v37/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "state"
                },
                {
                  "kind": "ScalarField",
                  "name": "endDate"
                }
              ]
            }
          ]
        }
      ]
    },
    (v37/*: any*/),
    (v46/*: any*/)
  ],
  "type": "JiraSprintField"
},
v94 = {
  "kind": "InlineFragment",
  "selections": [
    (v37/*: any*/),
    {
      "args": (v48/*: any*/),
      "concreteType": "JiraVersionConnection",
      "kind": "LinkedField",
      "name": "selectedVersionsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraVersionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraVersion",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v73/*: any*/)
            }
          ]
        }
      ],
      "storageKey": "selectedVersionsConnection(first:1000)"
    }
  ],
  "type": "JiraMultipleVersionPickerField"
},
v95 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraRichText",
      "kind": "LinkedField",
      "name": "richText",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraADF",
          "kind": "LinkedField",
          "name": "adfValue",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraAdfToConvertedPlainText",
              "kind": "LinkedField",
              "name": "convertedPlainText",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "plainText"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraRichTextField"
},
v96 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "concreteType": "JiraServiceManagementRequestType",
      "kind": "LinkedField",
      "name": "requestType",
      "plural": false,
      "selections": [
        (v37/*: any*/),
        (v86/*: any*/),
        (v25/*: any*/)
      ]
    }
  ],
  "type": "JiraServiceManagementRequestTypeField"
},
v97 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        }
      ],
      "concreteType": "JiraIssueLinkConnection",
      "kind": "LinkedField",
      "name": "issueLinkConnection",
      "plural": false,
      "selections": [
        (v55/*: any*/),
        {
          "concreteType": "JiraIssueLinkEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraIssueLink",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssue",
                  "kind": "LinkedField",
                  "name": "issue",
                  "plural": false,
                  "selections": [
                    (v88/*: any*/),
                    (v26/*: any*/),
                    {
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "ids",
                          "value": [
                            "statusCategory"
                          ]
                        }
                      ],
                      "concreteType": "JiraIssueFieldConnection",
                      "kind": "LinkedField",
                      "name": "fieldsById",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraIssueFieldEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v24/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v30/*: any*/)
                                  ],
                                  "type": "JiraStatusCategoryField"
                                },
                                (v25/*: any*/)
                              ]
                            }
                          ]
                        }
                      ],
                      "storageKey": "fieldsById(ids:[\"statusCategory\"])"
                    },
                    (v25/*: any*/)
                  ]
                },
                {
                  "kind": "ScalarField",
                  "name": "relationName"
                },
                (v25/*: any*/)
              ]
            }
          ]
        }
      ],
      "storageKey": "issueLinkConnection(first:1)"
    }
  ],
  "type": "JiraIssueLinkField"
},
v98 = {
  "kind": "ScalarField",
  "name": "number"
},
v99 = {
  "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isStoryPointField"
        },
        (v98/*: any*/),
        (v37/*: any*/),
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            (v98/*: any*/)
          ]
        },
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            (v98/*: any*/),
            (v37/*: any*/),
            (v46/*: any*/)
          ]
        },
        (v46/*: any*/)
      ],
      "type": "JiraNumberField"
    }
  ]
},
v100 = [
  {
    "concreteType": "JiraIssueFieldSetEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraIssueFieldSet",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v40/*: any*/),
          (v41/*: any*/),
          {
            "concreteType": "JiraIssueFieldConnection",
            "kind": "LinkedField",
            "name": "fields",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueFieldEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v24/*: any*/),
                      (v28/*: any*/),
                      (v40/*: any*/),
                      (v42/*: any*/),
                      (v25/*: any*/),
                      (v43/*: any*/),
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          (v47/*: any*/),
                          (v51/*: any*/),
                          (v53/*: any*/),
                          (v54/*: any*/),
                          (v56/*: any*/),
                          (v57/*: any*/),
                          (v61/*: any*/),
                          (v62/*: any*/),
                          (v63/*: any*/),
                          (v66/*: any*/),
                          (v67/*: any*/),
                          (v68/*: any*/),
                          (v69/*: any*/),
                          (v70/*: any*/)
                        ]
                      },
                      (v72/*: any*/),
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                        "kind": "Condition",
                        "passingValue": false,
                        "selections": [
                          (v74/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v36/*: any*/)
                            ],
                            "type": "JiraSingleLineTextField"
                          }
                        ]
                      },
                      (v76/*: any*/),
                      (v77/*: any*/),
                      (v79/*: any*/),
                      (v80/*: any*/),
                      (v54/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraStatusCategory",
                            "kind": "LinkedField",
                            "name": "statusCategory",
                            "plural": false,
                            "selections": [
                              (v37/*: any*/),
                              (v29/*: any*/),
                              (v25/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraStatusCategoryField"
                      },
                      (v81/*: any*/),
                      (v83/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "concreteType": "JiraIssueType",
                            "kind": "LinkedField",
                            "name": "issueType",
                            "plural": false,
                            "selections": [
                              (v39/*: any*/),
                              (v37/*: any*/),
                              (v25/*: any*/)
                            ]
                          }
                        ],
                        "type": "JiraIssueTypeField"
                      },
                      (v89/*: any*/),
                      (v90/*: any*/),
                      (v91/*: any*/),
                      (v92/*: any*/),
                      (v93/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v24/*: any*/),
                              (v64/*: any*/),
                              (v38/*: any*/),
                              (v37/*: any*/),
                              (v25/*: any*/),
                              (v65/*: any*/)
                            ]
                          },
                          (v37/*: any*/),
                          (v75/*: any*/),
                          (v46/*: any*/)
                        ],
                        "type": "JiraSingleSelectUserPickerField"
                      },
                      (v94/*: any*/),
                      (v95/*: any*/),
                      (v96/*: any*/),
                      (v97/*: any*/),
                      (v99/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
],
v101 = {
  "kind": "InlineFragment",
  "selections": [
    (v40/*: any*/),
    (v46/*: any*/)
  ],
  "type": "JiraSingleLineTextField"
},
v102 = {
  "args": [
    {
      "kind": "Variable",
      "name": "filterByProjectKeys",
      "variableName": "projectKeys"
    }
  ],
  "kind": "ScalarField",
  "name": "hasChildren"
},
v103 = {
  "kind": "ClientExtension",
  "selections": [
    (v33/*: any*/)
  ]
},
v104 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 500
  }
],
v105 = {
  "kind": "ScalarField",
  "name": "pageNumber"
},
v106 = {
  "kind": "ScalarField",
  "name": "isCurrent"
},
v107 = [
  (v105/*: any*/),
  (v23/*: any*/),
  (v106/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/),
      (v18/*: any*/),
      (v19/*: any*/),
      (v20/*: any*/)
    ],
    "kind": "Fragment",
    "name": "IssueNavigatorIssueSearchPaginationQuery",
    "selections": [
      {
        "args": [
          (v21/*: any*/),
          {
            "kind": "Variable",
            "name": "projectKeys",
            "variableName": "projectKeys"
          },
          {
            "kind": "Variable",
            "name": "shouldQueryHasChildren",
            "variableName": "shouldQueryHasChildren"
          }
        ],
        "kind": "FragmentSpread",
        "name": "main_issueNavigator_IssueNavigator_issueSearchData"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/),
      (v18/*: any*/),
      (v19/*: any*/),
      (v20/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider"
      },
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "IssueNavigatorIssueSearchPaginationQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider",
            "kind": "Condition",
            "passingValue": false,
            "selections": [
              {
                "args": (v22/*: any*/),
                "concreteType": "JiraIssueConnection",
                "kind": "LinkedField",
                "name": "issueSearch",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v23/*: any*/),
                      {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v24/*: any*/),
                          (v25/*: any*/),
                          (v26/*: any*/),
                          (v27/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "isResolved"
                          },
                          {
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "ids",
                                "value": [
                                  "status"
                                ]
                              }
                            ],
                            "concreteType": "JiraIssueFieldConnection",
                            "kind": "LinkedField",
                            "name": "fieldsById",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v24/*: any*/),
                                      (v31/*: any*/),
                                      (v25/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ],
                            "storageKey": "fieldsById(ids:[\"status\"])"
                          },
                          {
                            "concreteType": "JiraIssueTypeField",
                            "kind": "LinkedField",
                            "name": "issueTypeField",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueType",
                                "kind": "LinkedField",
                                "name": "issueType",
                                "plural": false,
                                "selections": [
                                  (v32/*: any*/),
                                  (v25/*: any*/)
                                ]
                              },
                              (v25/*: any*/)
                            ]
                          },
                          {
                            "kind": "ClientExtension",
                            "selections": [
                              (v33/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "isHighlightedIssueRow"
                              }
                            ]
                          },
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
                            "kind": "Condition",
                            "passingValue": false,
                            "selections": [
                              {
                                "args": (v35/*: any*/),
                                "concreteType": "JiraIssueFieldSetConnection",
                                "kind": "LinkedField",
                                "name": "fieldSetsForIssueSearchView",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldSetEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldSet",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraIssueFieldConnection",
                                            "kind": "LinkedField",
                                            "name": "fields",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "concreteType": "JiraIssueFieldEdge",
                                                "kind": "LinkedField",
                                                "name": "edges",
                                                "plural": true,
                                                "selections": [
                                                  {
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      (v24/*: any*/),
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v28/*: any*/),
                                                          (v36/*: any*/),
                                                          (v37/*: any*/)
                                                        ],
                                                        "type": "JiraSingleLineTextField"
                                                      },
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v28/*: any*/),
                                                          (v37/*: any*/),
                                                          {
                                                            "kind": "LinkedField",
                                                            "name": "user",
                                                            "plural": false,
                                                            "selections": [
                                                              (v24/*: any*/),
                                                              (v37/*: any*/),
                                                              (v38/*: any*/),
                                                              (v25/*: any*/)
                                                            ]
                                                          }
                                                        ],
                                                        "type": "JiraSingleSelectUserPickerField"
                                                      },
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v28/*: any*/),
                                                          {
                                                            "concreteType": "JiraIssueType",
                                                            "kind": "LinkedField",
                                                            "name": "issueType",
                                                            "plural": false,
                                                            "selections": [
                                                              (v37/*: any*/),
                                                              (v39/*: any*/),
                                                              (v25/*: any*/)
                                                            ]
                                                          }
                                                        ],
                                                        "type": "JiraIssueTypeField"
                                                      },
                                                      {
                                                        "kind": "InlineFragment",
                                                        "selections": [
                                                          (v28/*: any*/),
                                                          (v30/*: any*/)
                                                        ],
                                                        "type": "JiraStatusCategoryField"
                                                      },
                                                      (v25/*: any*/)
                                                    ]
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "condition": "shouldQueryFieldSetsById",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  {
                                    "args": [
                                      {
                                        "kind": "Variable",
                                        "name": "fieldSetIds",
                                        "variableName": "fieldSetIds"
                                      },
                                      (v34/*: any*/)
                                    ],
                                    "concreteType": "JiraIssueFieldSetConnection",
                                    "kind": "LinkedField",
                                    "name": "fieldSetsById",
                                    "plural": false,
                                    "selections": (v100/*: any*/)
                                  }
                                ]
                              },
                              {
                                "condition": "shouldQueryFieldSetsById",
                                "kind": "Condition",
                                "passingValue": false,
                                "selections": [
                                  {
                                    "args": (v35/*: any*/),
                                    "concreteType": "JiraIssueFieldSetConnection",
                                    "kind": "LinkedField",
                                    "name": "fieldSetsForIssueSearchView",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldSetEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "concreteType": "JiraIssueFieldSet",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v40/*: any*/),
                                              (v41/*: any*/),
                                              {
                                                "concreteType": "JiraIssueFieldConnection",
                                                "kind": "LinkedField",
                                                "name": "fields",
                                                "plural": false,
                                                "selections": [
                                                  {
                                                    "concreteType": "JiraIssueFieldEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                      {
                                                        "kind": "LinkedField",
                                                        "name": "node",
                                                        "plural": false,
                                                        "selections": [
                                                          (v28/*: any*/),
                                                          (v40/*: any*/),
                                                          (v42/*: any*/),
                                                          (v43/*: any*/),
                                                          {
                                                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                                            "kind": "Condition",
                                                            "passingValue": true,
                                                            "selections": [
                                                              (v47/*: any*/),
                                                              (v51/*: any*/),
                                                              (v53/*: any*/),
                                                              (v101/*: any*/),
                                                              (v56/*: any*/),
                                                              (v57/*: any*/),
                                                              (v61/*: any*/),
                                                              (v62/*: any*/),
                                                              (v63/*: any*/),
                                                              (v66/*: any*/),
                                                              (v67/*: any*/),
                                                              (v68/*: any*/),
                                                              (v69/*: any*/),
                                                              (v70/*: any*/)
                                                            ]
                                                          },
                                                          (v72/*: any*/),
                                                          {
                                                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider",
                                                            "kind": "Condition",
                                                            "passingValue": false,
                                                            "selections": [
                                                              (v74/*: any*/)
                                                            ]
                                                          },
                                                          (v76/*: any*/),
                                                          (v77/*: any*/),
                                                          (v79/*: any*/),
                                                          (v80/*: any*/),
                                                          (v101/*: any*/),
                                                          {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                              {
                                                                "concreteType": "JiraStatusCategory",
                                                                "kind": "LinkedField",
                                                                "name": "statusCategory",
                                                                "plural": false,
                                                                "selections": (v82/*: any*/)
                                                              }
                                                            ],
                                                            "type": "JiraStatusCategoryField"
                                                          },
                                                          (v81/*: any*/),
                                                          (v83/*: any*/),
                                                          (v89/*: any*/),
                                                          (v90/*: any*/),
                                                          (v91/*: any*/),
                                                          (v92/*: any*/),
                                                          (v93/*: any*/),
                                                          {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                              (v40/*: any*/),
                                                              {
                                                                "kind": "LinkedField",
                                                                "name": "user",
                                                                "plural": false,
                                                                "selections": [
                                                                  (v64/*: any*/),
                                                                  (v65/*: any*/)
                                                                ]
                                                              },
                                                              (v75/*: any*/),
                                                              (v46/*: any*/)
                                                            ],
                                                            "type": "JiraSingleSelectUserPickerField"
                                                          },
                                                          (v94/*: any*/),
                                                          (v95/*: any*/),
                                                          (v96/*: any*/),
                                                          (v97/*: any*/),
                                                          (v99/*: any*/)
                                                        ]
                                                      }
                                                    ]
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider",
                            "kind": "Condition",
                            "passingValue": true,
                            "selections": [
                              (v78/*: any*/),
                              {
                                "concreteType": "JiraIssueTypeField",
                                "kind": "LinkedField",
                                "name": "issueTypeField",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueType",
                                    "kind": "LinkedField",
                                    "name": "issueType",
                                    "plural": false,
                                    "selections": [
                                      (v39/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      (v24/*: any*/),
                      {
                        "condition": "shouldQueryHasChildren",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          (v102/*: any*/),
                          {
                            "args": [
                              (v21/*: any*/)
                            ],
                            "kind": "ScalarField",
                            "name": "canHaveChildIssues"
                          }
                        ]
                      },
                      (v103/*: any*/),
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          (v102/*: any*/)
                        ]
                      },
                      {
                        "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider",
                        "kind": "Condition",
                        "passingValue": true,
                        "selections": [
                          {
                            "args": (v104/*: any*/),
                            "concreteType": "JiraIssueFieldSetConnection",
                            "kind": "LinkedField",
                            "name": "fieldSets",
                            "plural": false,
                            "selections": (v100/*: any*/),
                            "storageKey": "fieldSets(first:500)"
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "startCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasPreviousPage"
                      }
                    ]
                  },
                  {
                    "concreteType": "JiraIssueNavigatorPageInfo",
                    "kind": "LinkedField",
                    "name": "issueNavigatorPageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "firstIssuePosition"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "lastIssuePosition"
                      }
                    ]
                  },
                  {
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "maxCursors",
                        "value": 7
                      }
                    ],
                    "concreteType": "JiraPageCursors",
                    "kind": "LinkedField",
                    "name": "pageCursors",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraPageCursor",
                        "kind": "LinkedField",
                        "name": "around",
                        "plural": true,
                        "selections": (v107/*: any*/)
                      },
                      {
                        "concreteType": "JiraPageCursor",
                        "kind": "LinkedField",
                        "name": "last",
                        "plural": false,
                        "selections": (v107/*: any*/)
                      },
                      {
                        "concreteType": "JiraPageCursor",
                        "kind": "LinkedField",
                        "name": "first",
                        "plural": false,
                        "selections": [
                          (v23/*: any*/),
                          (v105/*: any*/),
                          (v106/*: any*/)
                        ]
                      }
                    ],
                    "storageKey": "pageCursors(maxCursors:7)"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "totalIssueSearchResultCount"
                  },
                  {
                    "kind": "LinkedField",
                    "name": "issueSearchError",
                    "plural": false,
                    "selections": [
                      (v24/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "messages"
                          }
                        ],
                        "type": "JiraInvalidJqlError"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v71/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "errorType"
                          }
                        ],
                        "type": "JiraInvalidSyntaxError"
                      }
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "jql"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "isCappingIssueSearchResult"
                  },
                  (v103/*: any*/),
                  {
                    "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssueEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "args": (v104/*: any*/),
                            "concreteType": "JiraIssueFieldSetConnection",
                            "kind": "LinkedField",
                            "name": "fieldSets",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldSetEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraIssueFieldSet",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraIssueFieldConnection",
                                        "kind": "LinkedField",
                                        "name": "fields",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "concreteType": "JiraIssueFieldEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                              {
                                                "kind": "LinkedField",
                                                "name": "node",
                                                "plural": false,
                                                "selections": [
                                                  (v28/*: any*/),
                                                  (v24/*: any*/),
                                                  (v40/*: any*/),
                                                  (v25/*: any*/)
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ],
                            "storageKey": "fieldSets(first:500)"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "args": (v22/*: any*/),
                "filters": [
                  "issueSearchInput"
                ],
                "handle": "connection",
                "key": "IssueNavigatorIssueSearchPagination__issueSearch",
                "kind": "LinkedHandle",
                "name": "issueSearch"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "2202f78aa9fe773b5bc17ec12a057a6eb6f9fe75f26f53eecca1f6eb03658a9c",
    "metadata": {},
    "name": "IssueNavigatorIssueSearchPaginationQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "isRefactorNinToViewSchemaEnabled": isRefactorNinToViewSchemaEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcisrefactornintoviewschemaenabledrelayprovider": isRefactorNinToViewSchemaEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcisbulkopsinninenabledrelayprovider": bulkOpsInNinEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjiralistreparentingrelayprovider": isReparentingEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcisjscinfinitescrollenabledrelayprovider": isJscInfiniteScrollEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscm2fechangesrelayprovider": isJscM2Enabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcnikechinstorypointsinlineeditrelayprovider": isNikeChinStoryPointsInlineEditEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcjscinlineeditingfieldrefactorrelayprovider": isJscInlineEditRefactorEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone2experimentrelayprovider": includeSingleSelectColorField_provider
    }
  }
};
})();

(node as any).hash = "2effd46440f8aee61775fe79bc8cc67a";

export default node;
