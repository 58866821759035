import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { graphql, useFragment, useMutation } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isLoaderErrorAttributes } from '@atlassian/jira-errors-handling/src/utils/is-loader-error-attributes.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { type FlagConfiguration, useFlagService } from '@atlassian/jira-flags';
import AsyncAttachFileDialog from '@atlassian/jira-issue-operations-attach-file-dialog/src/ui/async.tsx';
import { DialogContextContainer } from '@atlassian/jira-issue-operations-dialog-context-provider/src/index.tsx';
import type { SelectValue } from '@atlassian/jira-jql-builder-basic-picker/src/common/types.tsx';
import {
	REORDER_COLUMNS,
	MAX_COLUMNS,
	MODIFY_COLUMNS,
} from '@atlassian/jira-native-issue-table/src/common/constants.tsx';
import { exclusiveGroupsOrIssuesFragment } from '@atlassian/jira-native-issue-table/src/common/utils/index.tsx';
import { useColumnWidthUpdater } from '@atlassian/jira-native-issue-table/src/controllers/use-column-width-updater/index.tsx';
import AsyncNativeIssueTable from '@atlassian/jira-native-issue-table/src/ui/async.tsx';
import type { Props as IssueTableProps } from '@atlassian/jira-native-issue-table/src/ui/types.tsx';
import type { ConnectionsSubscriberProps } from '@atlassian/jira-native-issue-table/src/common/types.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { main_issueNavigator_ListView_filter$key as FilterFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_ListView_filter.graphql';
import type { main_issueNavigator_ListView_groupResults$key as GroupResultsFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_ListView_groupResults.graphql';
import type { main_issueNavigator_ListView_issueResults$key as IssueResultsFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_ListView_issueResults.graphql';
import type { main_issueNavigator_ListView_project$key as ProjectFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_ListView_project.graphql';
import type { main_issueNavigator_ListView_view$key as ViewFragment } from '@atlassian/jira-relay/src/__generated__/main_issueNavigator_ListView_view.graphql';
import type { main_replaceListViewFieldSetsMutation as ReplaceListViewFieldSetsMutation } from '@atlassian/jira-relay/src/__generated__/main_replaceListViewFieldSetsMutation.graphql';
import type { main_replaceSpreadsheetViewFieldSetsMutation as ReplaceSpreadsheetViewFieldSetsMutation } from '@atlassian/jira-relay/src/__generated__/main_replaceSpreadsheetViewFieldSetsMutation.graphql';
import type {
	main_updateFieldSetPreferencesMutation as UpdateFieldSetPreferencesMutation,
	main_updateFieldSetPreferencesMutation$data as UpdateFieldSetPreferencesMutationData,
} from '@atlassian/jira-relay/src/__generated__/main_updateFieldSetPreferencesMutation.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import UFOLabel from '@atlassian/jira-ufo-label/src/index.tsx';
import { isRefactorNinToViewSchemaEnabled } from '@atlassian/jira-issue-navigator-rollout/src/is-refactor-nin-to-view-schema-enabled.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { PACKAGE_NAME, TEAM_NAME, views } from '../../../common/constants.tsx';
import type { OverridableIssueTableProps, SortDirection } from '../../../common/types.tsx';
import { withReportErrors } from '../../../common/ui/with-report-errors/index.tsx';
import {
	isFilterViewId,
	parseIssueNavigatorViewIdOrDefault,
} from '../../../common/utils/index.tsx';
import { markOnce, marks } from '../../../common/utils/performance-analytics.tsx';
import {
	useColumnPickerLoadingContext,
	ColumnPickerLoadingContextProvider,
} from '../../../controllers/column-picker-loading-context/index.tsx';
import { ColumnPickerMessageContext } from '../../../controllers/column-picker-message-context/index.tsx';
import { useSelectedIssueStateOldActions } from '../../../controllers/selected-issue-state-old/index.tsx';
import {
	useSelectedIssueIndex,
	useSelectedIssueKey,
} from '../../../controllers/selected-issue/facade.tsx';
import { useSelectedViewState } from '../../../controllers/selected-view-state/index.tsx';
import { useSortFieldAndDirection } from '../../../controllers/sort-field-and-direction-state/index.tsx';
import { useActiveJql, useResolvedJql } from '../../../services/active-jql/index.tsx';
import { useColumnLoader } from '../../../services/fetch-column-picker-options/index.tsx';
import { useInfiniteScroll } from '../../../services/infinite-scroll/index.tsx';
import { useIssueSearchQuery } from '../../../services/issue-search-query/index.tsx';
import { useIssueSearchActions } from '../../../services/issue-search/selectors.tsx';
import { useIFCSidebar } from '../../../common/ui/inline-field-config/sidebar/index.tsx';
import ColumnPickerPopupComponent from './column-picker-popup-component/index.tsx';
import { ColumnPickerRemountManager } from './column-picker-remount-manager/index.tsx';
import { Footer as IssueNavigatorFooter } from './footer/index.tsx';
import { BulkOpsConnectionsSubscriber } from './bulk-operations-connections-subscriber/index.tsx';
import { IssueCount } from './issue-count/index.tsx';
import messages from './messages.tsx';
import { NoColumns } from './no-columns/index.tsx';
import { NoIssues } from './no-issues/index.tsx';
import { AsyncRegisterShortcutDialogActions } from './register-shortcut-dialog-actions/async.tsx';
import { dragAndDropExperience } from './utils/drag-and-drop-experience.tsx';
import { useEventHandlers } from './utils/use-event-handlers.tsx';

export type Props = {
	project: ProjectFragment | null;
	loading: boolean;
	view: ViewFragment;
	onSelectedRowChange?: (rowIndex: number) => void;
	onChangeColumnConfiguration?: () => void;
	// @deprecated use onIssueFlatListUpdated instead. Remove when cleaning up bulk_operations_in_nin_experiment
	onIssueFlatListUpdatedOld?: (newFlatList: string[]) => void;
	onIssueFlatListUpdated?: (newFlatKeyList: string[], newFlatIdList: string[]) => void;
	issueTableProps?: OverridableIssueTableProps;
	isIssueHierarchySupportEnabled?: boolean;
	filter: FilterFragment | null;
} & (
	| {
			groupResults: GroupResultsFragment;
			issueResults: null;
	  }
	| { groupResults: null; issueResults: IssueResultsFragment }
);

const reorderColumnsErrorFlag: FlagConfiguration = {
	type: 'error',
	title: messages.reorderColumnsErrorTitle,
	description: messages.columnConfigMutationErrorDescription,
	isAutoDismiss: true,
	messageId: 'issue-navigator.ui.issue-results.list-view.reorder-columns-error-flag.error',
	messageType: 'transactional',
};

const resetColumnsErrorFlag: FlagConfiguration = {
	type: 'error',
	title: messages.resetColumnsErrorTitle,
	description: messages.columnConfigMutationErrorDescription,
	isAutoDismiss: true,
	messageId: 'issue-navigator.ui.issue-results.list-view.reset-columns-error-flag.error',
	messageType: 'transactional',
};

/**
 * @deprecated cleanup with jira_spreadsheet_component_m1
 */
type UseResettingReturn = {
	isRestoringDefaultColumns: boolean;
	setIsRestoringDefaultColumns: (isRestoringDefaultColumns: boolean) => void;
};

const TableContainer = ({ children }: { children: React.ReactNode }) =>
	getWillShowNav4() ? <Box xcss={TableContainerStyles}>{children}</Box> : children;

/**
 * @deprecated use ColumnPickerLoadingContext instead
 * cleanup with jira_spreadsheet_component_m1
 */
export const useRestoreDefaultColumnConfigState = (): UseResettingReturn => {
	const [isRestoringDefaultColumns, setIsRestoringDefaultColumns] = useState(false);
	return { isRestoringDefaultColumns, setIsRestoringDefaultColumns };
};

export const useCanEditColumnConfiguration = (): boolean => {
	const isAnonymous = useIsAnonymous();
	const { isFilterEditable } = useActiveJql();
	const [{ view }] = useSelectedViewState();

	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { isLoading } = useColumnPickerLoadingContext();

		return !isAnonymous && (!isFilterViewId(view) || isFilterEditable === true) && !isLoading;
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { isFetching, hasViewIdChanged, isRefreshing } = useIssueSearchQuery();
	const isFetchingView = isFetching && hasViewIdChanged;
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { isRestoringDefaultColumns } = useRestoreDefaultColumnConfigState();

	return (
		!isAnonymous &&
		(!isFilterViewId(view) || isFilterEditable === true) &&
		!isFetchingView &&
		!isRefreshing &&
		!isRestoringDefaultColumns
	);
};

export const useConditionalOnRestoreDefaults = (
	onRestoreDefaultColumns: () => void,
	hasDefaultFieldSets: boolean | null | undefined,
) => {
	const { isFilterEditable } = useActiveJql();
	const [{ view: selectedView }] = useSelectedViewState();
	return !(isFilterViewId(selectedView) && isFilterEditable === false) &&
		hasDefaultFieldSets === false
		? onRestoreDefaultColumns
		: undefined;
};

/**
 * the presence of conditionalOnChange determines whether the user has column configuration capabilities.
 * hence we only define conditionalOnChange hook when a user can edit a column
 */
export const useConditionalOnChange = (
	onColumnsChange: NonNullable<IssueTableProps['onColumnsChange']>,
) => {
	const canEditColumns = useCanEditColumnConfiguration();
	return canEditColumns ? onColumnsChange : undefined;
};

export const useColumnLoaderForSelectedView = (selectedColumns: SelectValue) => {
	const [{ view: selectedView }] = useSelectedViewState();
	const canEditColumns = useCanEditColumnConfiguration();
	return useColumnLoader(
		selectedView,
		canEditColumns,
		selectedColumns,
		expVal('jira_inline_field_config', 'isInlineFieldConfigEnabled', false),
	);
};

/**
 * only a logged in user can view the column picker,
 * hence we only define the useColumnLoader hook for logged in users.
 */
export const useConditionalColumnLoader = () => {
	const isAnonymous = useIsAnonymous();
	return !isAnonymous ? useColumnLoaderForSelectedView : undefined;
};

/**
 * @deprecated - use ColumnPickerLoadingContext instead
 * cleanup with jira_spreadsheet_component_m1
 */
export const useColumnPickerLoading = () => {
	const { hasViewIdChanged, isFetching } = useIssueSearchQuery();
	const isFetchingView = isFetching && hasViewIdChanged;
	const { isRestoringDefaultColumns } = useRestoreDefaultColumnConfigState();

	return {
		/**
		 * @deprecated - use ColumnPickerLoadingContext instead
		 */
		isColumnPickerLoading: isRestoringDefaultColumns || isFetchingView,
	};
};

const ListView = ({
	loading,
	onChangeColumnConfiguration,
	onSelectedRowChange,
	issueResults,
	groupResults,
	view,
	project,
	issueTableProps,
	isIssueHierarchySupportEnabled,
	filter,
	onIssueFlatListUpdatedOld,
	onIssueFlatListUpdated,
}: Props) => {
	markOnce(marks.ISSUE_RESULTS_LIST_VIEW_START);

	useLayoutEffect(() => {
		markOnce(marks.ISSUE_RESULTS_LIST_VIEW_END);
	}, []);
	const resolvedJql = useResolvedJql();

	/* eslint-disable @atlassian/relay/must-colocate-fragment-spreads */
	const issueResultsData = useFragment<IssueResultsFragment>(
		graphql`
			fragment main_issueNavigator_ListView_issueResults on JiraIssueConnection
			@argumentDefinitions(
				projectKeys: { type: "[String!]" }
				projectKey: { type: "String" }
				shouldQueryHasChildren: { type: "Boolean!" }
				bulkOpsInNinEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/is-bulk-ops-in-nin-enabled.relayprovider"
				}
				isReparentingEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/jira-list-reparenting.relayprovider"
				}
			) {
				__id
				...registerShortcutDialogActions_issueNavigator_RegisterShortcutsDialogActions
				...ui_nativeIssueTable_NativeIssueTable_issues
					@arguments(
						projectKeys: $projectKeys
						projectKey: $projectKey
						shouldQueryHasChildren: $shouldQueryHasChildren
						isReparentingEnabled: $isReparentingEnabled
					)
				pageCursors(maxCursors: 7) {
					around {
						pageNumber
						isCurrent
					}
				}
				...ui_issueNavigatorBulkOperationsConnectionsSubscriber_BulkOpsConnectionsSubscriber
					@include(if: $bulkOpsInNinEnabled)
			}
		`,
		issueResults,
	);

	const groupResultsData = useFragment<GroupResultsFragment>(
		graphql`
			fragment main_issueNavigator_ListView_groupResults on JiraSpreadsheetGroupConnection
			@argumentDefinitions(
				isPaginating: { type: "Boolean!" }
				projectKeys: { type: "[String!]" }
				projectKey: { type: "String" }
				bulkOpsInNinEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/is-bulk-ops-in-nin-enabled.relayprovider"
				}
			) {
				...ui_nativeIssueTable_NativeIssueTable_groups
					@arguments(
						isPaginating: $isPaginating
						projectKeys: $projectKeys
						projectKey: $projectKey
					)
				firstGroup {
					issues(fieldSetsInput: $fieldSetsInput, first: 50) {
						...ui_issueNavigatorBulkOperationsConnectionsSubscriber_BulkOpsConnectionsSubscriber
							@include(if: $bulkOpsInNinEnabled)
					}
				}
			}
		`,
		groupResults,
	);

	const viewData = useFragment<ViewFragment>(
		graphql`
			fragment main_issueNavigator_ListView_view on JiraIssueSearchViewMetadata
			@argumentDefinitions(
				isJscM2Enabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/jsc-m2-fe-changes.relayprovider"
				}
			) {
				__typename
				id @required(action: THROW)
				viewId @required(action: THROW)
				hasDefaultFieldSets
				fieldSets(first: $amountOfColumns, filter: { fieldSetSelectedState: SELECTED })
					@required(action: THROW) {
					__id
					totalCount
					edges {
						node {
							fieldSetId
						}
					}
					...ui_nativeIssueTable_NativeIssueTable_columns
				}
				... on JiraIssueSearchView {
					viewConfigSettings(staticViewInput: $staticViewInput) @include(if: $isJscM2Enabled) {
						isHierarchyEnabled
						canEnableHierarchy
						hideDone
					}
				}
				... on JiraSpreadsheetView {
					viewSettings(
						groupBy: $groupBy
						staticViewInput: $staticViewInput
						issueSearchInput: $issueSearchInput
					) {
						canEnableHierarchy
						groupByConfig {
							groupByField {
								fieldId
							}
						}
						isHierarchyEnabled
						hideDone
					}
				}
			}
		`,
		view,
	);

	const projectData = useFragment(
		graphql`
			fragment main_issueNavigator_ListView_project on JiraProject {
				key
				projectId
				...ui_nativeIssueTable_NativeIssueTable_project
			}
		`,
		project,
	);

	const filterData = useFragment<FilterFragment>(
		graphql`
			fragment main_issueNavigator_ListView_filter on JiraFilter {
				name
			}
		`,
		filter,
	);

	const selectedIssueKey = useSelectedIssueKey();
	const selectedIssueIndex = useSelectedIssueIndex();

	const currentPage =
		issueResultsData?.pageCursors?.around?.find(
			(
				cursor:
					| {
							readonly isCurrent: boolean | null | undefined;
							readonly pageNumber: number | null | undefined;
					  }
					| null
					| undefined,
			) => cursor?.isCurrent,
		)?.pageNumber ?? 1;

	const [{ field, direction }, { setFieldAndDirection }] = useSortFieldAndDirection();

	let onIssueSearchForColumnConfig;
	let onRefresh;
	let isColumnPickerLoading: boolean;
	let isRestoringDefaultColumns: boolean;
	let setIsRestoringDefaultColumns: (newVal: boolean) => void;

	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// TODO JSC-242 Need to figure out appropriate refetch behaviour, e.g. do we want to try keep visible issues in view?
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ onRefetch: onIssueSearchForColumnConfig, onRefresh } = useIssueSearchActions());

		({ isLoading: isColumnPickerLoading, setIsRestoringDefaultColumns } =
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useColumnPickerLoadingContext());
		isRestoringDefaultColumns = false; // Cleanup with jira_spreadsheet_component_m1
	} else {
		({
			onIssueSearchForCurrentPage: onIssueSearchForColumnConfig,
			onIssueSearchRefresh: onRefresh,
			// eslint-disable-next-line react-hooks/rules-of-hooks
		} = useIssueSearchQuery());

		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ isColumnPickerLoading } = useColumnPickerLoading());
		({ isRestoringDefaultColumns, setIsRestoringDefaultColumns } =
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useRestoreDefaultColumnConfigState());
	}

	const onModifyColumns = useCallback(() => {
		onChangeColumnConfiguration && onChangeColumnConfiguration();
		onIssueSearchForColumnConfig();
	}, [onChangeColumnConfiguration, onIssueSearchForColumnConfig]);

	let onPageChange;
	let fieldSetIds: string[] = [];
	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		fieldSetIds = useMemo(
			() => viewData.fieldSets.edges?.map((edge) => edge?.node?.fieldSetId).filter(Boolean) ?? [],
			[viewData.fieldSets.edges],
		);
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { selectIssueOnPage } = useSelectedIssueStateOldActions();
		// eslint-disable-next-line react-hooks/rules-of-hooks
		onPageChange = useCallback(
			(cursor: string, shouldSelectLastIssue: boolean) => {
				selectIssueOnPage(cursor, shouldSelectLastIssue);
			},
			[selectIssueOnPage],
		);
	}

	const eventHandlers = useEventHandlers(issueResultsData?.__id, fieldSetIds);
	const overriddenTableComponents = issueTableProps?.components;
	const projectContext = issueTableProps?.projectContext;
	const isGroupingSupported = issueTableProps?.isGroupingSupported;

	let infiniteScrollProps = {};

	const viewSettings = isRefactorNinToViewSchemaEnabled()
		? viewData?.viewSettings
		: viewData.viewConfigSettings;

	/**
	 * isHierarchyEnabled reflects user preference in the current page,
	 * canEnableHierarchy reflects whether the hierarchy can be enabled based on the JQL / other settings,
	 * isIssueHierarchySupportEnabled reflects whether the hierarchy is supported in the experience.
	 * The combination of these three values determines whether issue hierarchy is enabled in issue table.
	 */
	const isHierarchyEnabled =
		viewSettings?.isHierarchyEnabled &&
		viewSettings.canEnableHierarchy &&
		isIssueHierarchySupportEnabled;

	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		infiniteScrollProps = useInfiniteScroll({
			isHierarchyEnabled,
			fieldSetIds,
			groupBy: isGroupingSupported
				? viewData?.viewSettings?.groupByConfig?.groupByField?.fieldId
				: undefined,
			view: views.list,
			isIssueHierarchySupportEnabled,
			isHideDoneEnabled: viewSettings?.hideDone || false,
		});
	}

	const onSortOrderChange = useCallback(
		(sortField: string, sortDirection: SortDirection) =>
			setFieldAndDirection(sortField, sortDirection, []),
		[setFieldAndDirection],
	);

	const hasMaxSelectedColumns = viewData?.fieldSets?.totalCount
		? viewData?.fieldSets?.totalCount >= MAX_COLUMNS
		: false;

	const isAnonymous = useIsAnonymous();

	const { showFlag } = useFlagService();

	let Footer;
	if (expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)) {
		Footer = undefined;
	} else {
		Footer = IssueNavigatorFooter;
	}

	const tableComponents = useMemo(
		// We shallow merge these objects to make it easier for issue-navigator consumers to revert custom column
		// picker components, which are very NIN specific. This decision can be reconsidered if we need to introduce
		// additional nested custom components in the issue table and make those available to all consumers.
		() => ({
			columnPicker: {
				Container: ({ children }: { children: React.ReactNode }) => (
					<ColumnPickerRemountManager
						viewIdFromResponse={parseIssueNavigatorViewIdOrDefault(viewData.viewId)}
						isRestoringDefaults={isRestoringDefaultColumns}
					>
						{children}
					</ColumnPickerRemountManager>
				),
				PopupComponent: ColumnPickerPopupComponent,
			},
			Footer,
			IssueCount,
			NoColumns,
			NoIssues,
			...overriddenTableComponents,
			ConnectionsSubscriber: expVal('bulk_operations_in_nin_experiment', 'isEnabled', false)
				? ({ connections, updateConnectionDetails }: ConnectionsSubscriberProps) => (
						<BulkOpsConnectionsSubscriber
							connections={connections}
							updateConnectionDetails={updateConnectionDetails}
							fragmentRef={issueResultsData || groupResultsData?.firstGroup?.issues || null}
						/>
					)
				: undefined,
		}),
		[
			Footer,
			overriddenTableComponents,
			viewData.viewId,
			isRestoringDefaultColumns,
			issueResultsData,
			groupResultsData?.firstGroup?.issues,
		],
	);

	const [replaceListViewFieldSets] = useMutation<ReplaceListViewFieldSetsMutation>(graphql`
		mutation main_replaceListViewFieldSetsMutation(
			$id: ID!
			$input: JiraReplaceIssueSearchViewFieldSetsInput
			$fieldSetsInput: JiraFieldSetsMutationInput
			$amountOfColumns: Int!
			$includeView: Boolean!
		) @raw_response_type {
			jira {
				replaceIssueSearchViewFieldSets(id: $id, input: $input, fieldSetsInput: $fieldSetsInput) {
					success
					view @include(if: $includeView) {
						id
						viewId
						hasDefaultFieldSets
						fieldSets(first: $amountOfColumns, filter: { fieldSetSelectedState: SELECTED }) {
							__id
							totalCount
							edges {
								node {
									fieldSetId
								}
							}
							...ui_nativeIssueTable_NativeIssueTable_columns
						}
					}
				}
			}
		}
	`);

	const [replaceSpreadsheetViewFieldSets] = useMutation<ReplaceSpreadsheetViewFieldSetsMutation>(
		graphql`
			mutation main_replaceSpreadsheetViewFieldSetsMutation(
				$id: ID!
				$fieldSetsInput: JiraFieldSetsMutationInput
				$amountOfColumns: Int!
				$includeView: Boolean!
			) @raw_response_type {
				jira {
					replaceSpreadsheetViewFieldSets(fieldSetsInput: $fieldSetsInput, id: $id)
						@optIn(to: "JiraIssueSearchView") {
						success
						view @include(if: $includeView) {
							hasDefaultFieldSets
							fieldSets(first: $amountOfColumns, filter: { fieldSetSelectedState: SELECTED }) {
								__id
								totalCount
								edges {
									node {
										fieldSetId
									}
								}
								...ui_nativeIssueTable_NativeIssueTable_columns
							}
						}
					}
				}
			}
		`,
	);

	const onColumnsChange = useCallback<NonNullable<IssueTableProps['onColumnsChange']>>(
		(columnIds, changeType, optimisticFieldSets, onSuccessCallback) => {
			// This handler can be called from a native event listener hence React does not batch state updates.
			// We manually batch this update for better rendering performance and more accurate measurement of
			// drag-and-drop interaction performance. We can remove this with React 18's automatic batching.
			ReactDOM.unstable_batchedUpdates(() => {
				if (changeType === REORDER_COLUMNS) {
					dragAndDropExperience.start();
				}

				const variables = {
					id: viewData.id,
					amountOfColumns: MAX_COLUMNS,
					fieldSetsInput: { replaceFieldSetsInput: { nodes: columnIds } },
					includeView: changeType === REORDER_COLUMNS,
				};

				const handleCompleted = (success?: boolean) => {
					if (success) {
						if (changeType === MODIFY_COLUMNS) {
							onModifyColumns();
						} else if (changeType === REORDER_COLUMNS) {
							dragAndDropExperience.success();
						}
						if (expVal('jira_inline_field_config', 'isInlineFieldConfigEnabled', false)) {
							onSuccessCallback?.();
						}
					} else {
						showFlag(reorderColumnsErrorFlag);
						fireErrorAnalytics({
							meta: {
								id: 'commitReplaceListViewFieldSetsMutationUnsuccessful',
								packageName: PACKAGE_NAME,
								teamName: TEAM_NAME,
							},
							sendToPrivacyUnsafeSplunk: true,
							attributes: {
								changeType,
							},
						});
						if (changeType === REORDER_COLUMNS) {
							dragAndDropExperience.failure();
						}
					}
				};

				const handleError = (error: Error) => {
					showFlag(reorderColumnsErrorFlag);
					fireErrorAnalytics({
						meta: {
							id: 'commitReplaceListViewFieldSetsMutationError',
							packageName: PACKAGE_NAME,
							teamName: TEAM_NAME,
						},
						error,
						sendToPrivacyUnsafeSplunk: true,
						attributes: {
							changeType,
						},
					});
					if (changeType === REORDER_COLUMNS) {
						dragAndDropExperience.failure();
					}
				};

				if (isRefactorNinToViewSchemaEnabled()) {
					replaceSpreadsheetViewFieldSets({
						variables,
						optimisticResponse:
							changeType === REORDER_COLUMNS && optimisticFieldSets
								? {
										jira: {
											replaceSpreadsheetViewFieldSets: {
												success: true,
												view: {
													__typename: viewData.__typename,
													id: viewData.id,
													hasDefaultFieldSets: viewData.hasDefaultFieldSets,
													fieldSets: {
														...optimisticFieldSets,
													},
												},
											},
										},
									}
								: undefined,
						onCompleted(data) {
							handleCompleted(data.jira?.replaceSpreadsheetViewFieldSets?.success);
						},
						onError(error) {
							handleError(error);
						},
					});
				} else {
					replaceListViewFieldSets({
						variables,
						optimisticResponse:
							changeType === REORDER_COLUMNS && optimisticFieldSets
								? {
										jira: {
											replaceIssueSearchViewFieldSets: {
												success: true,
												view: {
													id: viewData.id,
													viewId: viewData.viewId,
													hasDefaultFieldSets: viewData.hasDefaultFieldSets,
													fieldSets: {
														...optimisticFieldSets,
													},
												},
											},
										},
									}
								: undefined,
						onCompleted(data) {
							handleCompleted(data.jira?.replaceIssueSearchViewFieldSets?.success);
						},
						onError(error) {
							handleError(error);
						},
					});
				}
			});
		},
		[
			viewData.id,
			viewData.__typename,
			viewData.hasDefaultFieldSets,
			viewData.viewId,
			onModifyColumns,
			showFlag,
			replaceSpreadsheetViewFieldSets,
			replaceListViewFieldSets,
		],
	);

	const [updateFieldSetPreferences] = useMutation<UpdateFieldSetPreferencesMutation>(graphql`
		mutation main_updateFieldSetPreferencesMutation(
			$cloudId: ID!
			$input: JiraFieldSetPreferencesMutationInput!
		) {
			jira {
				updateUserFieldSetPreferences(cloudId: $cloudId, fieldSetPreferencesInput: $input)
					@optIn(to: "JiraUpdateUserFieldSetPreferences") {
					success
				}
			}
		}
	`);

	const cloudId = useCloudId();

	const updateColumnWidth = useColumnWidthUpdater(viewData.fieldSets.__id);

	const onColumnResize = useCallback<NonNullable<IssueTableProps['onColumnResize']>>(
		(columnId, width) => {
			// skip mutation for anonymous users as they don't have access to user preferences
			if (!isAnonymous) {
				// we intentionally don't optimistically update the store to avoid rolling back column widths
				// in case of a mutation error, which would be very jarring for the user
				updateFieldSetPreferences({
					variables: {
						cloudId,
						input: {
							nodes: [
								{
									fieldSetId: columnId,
									width: width ?? null,
								},
							],
						},
					},
					onCompleted(data: UpdateFieldSetPreferencesMutationData) {
						if (!data.jira?.updateUserFieldSetPreferences?.success) {
							// silently fail as column will remain at the desired width until the next page load
							fireErrorAnalytics({
								meta: {
									id: 'commitUpdateFieldSetPreferencesMutationUnsuccessful',
									packageName: PACKAGE_NAME,
									teamName: TEAM_NAME,
								},
								sendToPrivacyUnsafeSplunk: true,
							});
						}
					},
					onError(error: Error) {
						// silently fail as column will remain at the desired width until the next page load
						fireErrorAnalytics({
							meta: {
								id: 'commitUpdateFieldSetPreferencesMutationError',
								packageName: PACKAGE_NAME,
								teamName: TEAM_NAME,
							},
							error,
							sendToPrivacyUnsafeSplunk: true,
						});
					},
				});
			}

			// commit local update to the store independently of the mutation result to preserve user's desired width
			updateColumnWidth(columnId, width);
		},
		[cloudId, isAnonymous, updateColumnWidth, updateFieldSetPreferences],
	);

	useLayoutEffect(() => {
		dragAndDropExperience.markInlineResponse();
	});

	const contextValue = useMemo(
		() => ({
			hasMaxSelectedColumns,
			filterName: filterData?.name,
		}),
		[filterData?.name, hasMaxSelectedColumns],
	);

	const onRestoreDefaultColumns = useCallback<
		NonNullable<IssueTableProps['onRestoreDefaultColumns']>
	>(() => {
		setIsRestoringDefaultColumns(true);

		const variables = {
			id: viewData.id,
			amountOfColumns: MAX_COLUMNS,
			includeView: true,
			fieldSetsInput: { resetToDefaultFieldSets: true },
		};

		const handleCompleted = (success?: boolean) => {
			setIsRestoringDefaultColumns(false);
			if (success) {
				onRefresh();
			} else {
				showFlag(resetColumnsErrorFlag);
				fireErrorAnalytics({
					meta: {
						id: 'commitReplaceListViewUsingResetFieldSetsMutationUnsuccessful',
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
					sendToPrivacyUnsafeSplunk: true,
				});
			}
		};

		const handleError = (error: Error) => {
			setIsRestoringDefaultColumns(false);
			showFlag(resetColumnsErrorFlag);
			fireErrorAnalytics({
				meta: {
					id: 'commitReplaceListViewUsingResetFieldSetsMutationError',
					packageName: PACKAGE_NAME,
					teamName: TEAM_NAME,
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
		};

		if (isRefactorNinToViewSchemaEnabled()) {
			replaceSpreadsheetViewFieldSets({
				variables,
				onCompleted(data) {
					handleCompleted(data.jira?.replaceSpreadsheetViewFieldSets?.success);
				},
				onError(error) {
					handleError(error);
				},
			});
		} else {
			replaceListViewFieldSets({
				variables,
				onCompleted(data) {
					handleCompleted(data.jira?.replaceIssueSearchViewFieldSets?.success);
				},
				onError(error) {
					handleError(error);
				},
			});
		}
	}, [
		setIsRestoringDefaultColumns,
		viewData.id,
		onRefresh,
		showFlag,
		replaceSpreadsheetViewFieldSets,
		replaceListViewFieldSets,
	]);

	/**
	 * returns undefined if there are default field sets
	 * returns undefined if the user is on filter tab but cannot edit filter
	 */
	const conditionalOnRestoreDefaults = useConditionalOnRestoreDefaults(
		onRestoreDefaultColumns,
		viewData?.hasDefaultFieldSets,
	);

	const conditionalOnChange: typeof onColumnsChange | undefined =
		useConditionalOnChange(onColumnsChange);

	const conditionalColumnLoader: IssueTableProps['useColumnLoader'] | undefined =
		useConditionalColumnLoader();

	const [isIFCEnabled, ifcConfig] = expVal(
		'jira_inline_field_config',
		'isInlineFieldConfigEnabled',
		false,
	)
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIFCSidebar({
				projectKey: projectData?.key,
				projectId: projectData?.projectId,
			})
		: [false, undefined];

	const resultsFragment = exclusiveGroupsOrIssuesFragment({
		groups: groupResultsData,
		issues: issueResultsData,
	});

	if (!resultsFragment) {
		throw new Error('Missing groups or issues data');
	}

	return (
		<UFOLabel name="list-view">
			<DialogContextContainer>
				<ContextualAnalyticsData attributes={{ currentPage, isAnonymous }}>
					{resultsFragment.issues && (
						<AsyncRegisterShortcutDialogActions
							issues={resultsFragment.issues}
							// TODO Remove when cleaning up jira_spreadsheet_component_m1
							selectedIssueIndex={selectedIssueIndex}
							selectedIssueKey={selectedIssueKey}
							eventHandlers={eventHandlers}
						/>
					)}
					<ColumnPickerMessageContext.Provider value={contextValue}>
						<TableContainer>
							<AsyncNativeIssueTable
								columns={viewData.fieldSets}
								onColumnsChange={conditionalOnChange}
								onRestoreDefaultColumns={conditionalOnRestoreDefaults}
								useColumnLoader={conditionalColumnLoader}
								loading={loading}
								eventHandlers={eventHandlers}
								onSelectedRowChange={onSelectedRowChange}
								onPageChange={onPageChange}
								selectedRow={selectedIssueIndex}
								sortField={field}
								sortDirection={direction}
								onSortChange={onSortOrderChange}
								onRefetch={onIssueSearchForColumnConfig}
								components={tableComponents}
								issueSearchBaseUrl="" // Relative to current route rendering the issue navigator
								isColumnPickerLoading={isColumnPickerLoading}
								projectContext={projectContext}
								isGroupingSupported={isGroupingSupported}
								isIssueCreateEnabled // It's already tied to the project context
								onColumnResize={onColumnResize}
								project={projectData}
								isIssueHierarchyEnabled={Boolean(isHierarchyEnabled)}
								isInlineEditingExtendedFieldSupportEnabled={expVal(
									'jira_list_inline_editing',
									'isInlineEditingEnabled',
									false,
								)}
								isInBetweenColumnPickerEnabled={expVal(
									'jira_inline_field_config',
									'isInlineFieldConfigEnabled',
									false,
								)}
								isInlineFieldConfigEnabled={expVal(
									'jira_inline_field_config',
									'isInlineFieldConfigEnabled',
									false,
								)}
								ifcConfig={isIFCEnabled ? ifcConfig : undefined}
								onIssueFlatListUpdatedOld={onIssueFlatListUpdatedOld}
								onIssueFlatListUpdated={onIssueFlatListUpdated}
								{...(fg('jsc_m2_hierarchy_fe_changes') && { jql: resolvedJql })}
								{...(expVal('bulk_operations_in_nin_experiment', 'isEnabled', false) && {
									enableMultiSelect: true,
								})}
								{...(expVal('jira_list_reparenting', 'isReparentingEnabled', false) && {
									isReparentingEnabled: true,
								})}
								{...(expVal('jira_list_single_line_row_height', 'isEnabled', false) && {
									isSingleLineRowHeightEnabled: true,
								})}
								{...(expVal('jira_list_hide_done_items', 'isEnabled', false) && {
									isHideDoneItemsEnabled: viewSettings?.hideDone ?? false,
								})}
								{...infiniteScrollProps}
								{...resultsFragment}
								preloadedEntryPoints={issueTableProps?.preloadedEntryPoints}
							/>
						</TableContainer>
					</ColumnPickerMessageContext.Provider>
				</ContextualAnalyticsData>
				<AsyncAttachFileDialog />
				<FireScreenAnalytics />
			</DialogContextContainer>
		</UFOLabel>
	);
};

// re-render regression detection
ListView.whyDidYouRender = true;

const ListViewWithColumnPickerContext = (props: Props) => (
	<ColumnPickerLoadingContextProvider>
		<ListView {...props} />
	</ColumnPickerLoadingContextProvider>
);

const ConditionalListView = componentWithCondition(
	() => expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false),
	ListViewWithColumnPickerContext,
	ListView,
);

export default withReportErrors<Props>(ConditionalListView, {
	id: 'ui.issue-results.list-view.unhandled',
	packageName: PACKAGE_NAME,
	teamName: TEAM_NAME,
	sendToPrivacyUnsafeSplunk: true,
	attributes: isLoaderErrorAttributes,
});

const TableContainerStyles = xcss({
	flexGrow: 1,
	flexBasis: 0,
	display: 'flex',
	height: '100%',
	overflow: 'hidden',
	flexDirection: 'column',
});
