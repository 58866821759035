import { useState, useEffect, useCallback, useMemo } from 'react';
import { useCoordination } from '@atlassiansox/engagekit-ts';
import { CoordinationClient } from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import {
	ONBOARDING_ENGAGEMENT_ID,
	ONBOARDING_STATISTICS_SPOTLIGHT_ID,
	ONBOARRDING_ENGAGEMENT_RELAUNCH_ID,
	STARGATE_URL,
} from './constants.tsx';

export const useStatisticsSpotlight = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const cloudId = useCloudId();
	const [showPulse, setShowPulse] = useState(false);

	const coordinationClient = useMemo(
		() => new CoordinationClient(cloudId, STARGATE_URL, false, '', 'engagement'),
		[cloudId],
	);

	const [isMessageActive, stopMessage] = useCoordination(
		coordinationClient,
		fg('helios_nin_statistics_onboarding_spotlight')
			? ONBOARRDING_ENGAGEMENT_RELAUNCH_ID
			: ONBOARDING_ENGAGEMENT_ID,
	);

	useEffect(() => {
		if (isMessageActive) {
			setShowPulse(true);
			if (fg('jira_nin_charts_missing_analytics')) {
				fireUIAnalytics(
					createAnalyticsEvent({ action: 'viewed', actionSubject: 'spotlight' }),
					ONBOARDING_STATISTICS_SPOTLIGHT_ID,
				);
			}
		}
	}, [coordinationClient, createAnalyticsEvent, isMessageActive]);

	const shouldShowSpotlight = useMemo(
		() => showPulse && expVal('helios_list_view_statistics_nin_migration', 'isEnabled', false),
		[showPulse],
	);

	const onStatisticsOnboardingStop = useCallback(
		(actionSubject: string) => {
			stopMessage();
			setShowPulse(false);
			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'clicked',
					actionSubject: `${actionSubject} button`,
				}),
			);
		},
		[stopMessage, createAnalyticsEvent],
	);
	return {
		shouldShowSpotlight,
		onStatisticsOnboardingStop,
		coordinationClient,
	};
};
