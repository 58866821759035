import { useCallback } from 'react';
import { graphql, useMutation } from 'react-relay';
import { MutationError } from '@atlassian/jira-mutation-error/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import type { updateHideDoneToggleMutation } from '@atlassian/jira-relay/src/__generated__/updateHideDoneToggleMutation.graphql.ts';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import {
	HIDE_DONE_EXPERIENCE,
	ANALYTICS_SOURCE,
	PACKAGE_NAME,
	TEAM_NAME,
} from '../../common/constants.tsx';
import messages from './messages.tsx';

export const useUpdateHideDoneMutation = () => {
	const isAnonymous = useIsAnonymous();

	const onExperienceStart = useExperienceStart({
		experience: HIDE_DONE_EXPERIENCE,
		analyticsSource: ANALYTICS_SOURCE,
	});

	const onExperienceFail = useExperienceFail({
		experience: HIDE_DONE_EXPERIENCE,
	});

	const onExperienceSuccess = useExperienceSuccess({
		experience: HIDE_DONE_EXPERIENCE,
	});

	const [commit] = useMutation<updateHideDoneToggleMutation>(graphql`
		mutation updateHideDoneToggleMutation($viewId: ID!, $isHideDoneEnabled: Boolean!) {
			jira {
				updateIssueSearchHideDonePreference(viewId: $viewId, isHideDoneEnabled: $isHideDoneEnabled)
					@optIn(to: "JiraUpdateIssueSearchHideDonePreference") {
					success
					errors {
						message
						extensions {
							statusCode
						}
					}
				}
			}
		}
	`);

	const { showFlag } = useFlagsService();
	const { formatMessage } = useIntl();

	const handleError = useCallback(
		(error: MutationError | Error) => {
			fireErrorAnalytics({
				meta: {
					id: 'issue-table-hide-done-toggle',
					packageName: PACKAGE_NAME,
					teamName: TEAM_NAME,
				},
				error,
			});
			onExperienceFail(PACKAGE_NAME, error);
			showFlag({
				messageId: 'issue-table-hide-done-toggle.services.update-hide-done-toggle.show-flag.error',
				messageType: 'transactional',
				id: 'hideDoneToggleError',
				title: formatMessage(messages.hideDoneErrorFlagTitle),
				description: formatMessage(messages.hideDoneErrorFlagDescription),
				isAutoDismiss: true,
				type: 'error',
			});
		},
		[formatMessage, onExperienceFail, showFlag],
	);

	const updateHideDone = useCallback(
		({
			issueSearchViewResultAri,
			isHideDoneEnabled,
			onChangeHideDone,
		}: {
			issueSearchViewResultAri: string;
			isHideDoneEnabled: boolean;
			onChangeHideDone: (isHideDoneEnabled: boolean) => void;
		}) => {
			if (isAnonymous) {
				onChangeHideDone(isHideDoneEnabled);
				return;
			}
			onExperienceStart();

			commit({
				variables: {
					viewId: issueSearchViewResultAri,
					isHideDoneEnabled,
				},
				optimisticUpdater() {
					onChangeHideDone(isHideDoneEnabled);
				},
				onCompleted: (response) => {
					const { errors } = response?.jira?.updateIssueSearchHideDonePreference ?? {};
					if (errors && errors.length > 0) {
						const error = new MutationError(errors[0]);
						handleError(error);
						return;
					}

					onExperienceSuccess();
				},
				onError: handleError,
			});
		},
		[commit, handleError, isAnonymous, onExperienceStart, onExperienceSuccess],
	);

	return updateHideDone;
};
