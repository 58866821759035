import React, { useCallback, useMemo } from 'react';
import { Box, Flex } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { cssMap } from '@atlaskit/css';
import { token } from '@atlaskit/tokens';
import { useSidebar } from '@atlassian/jira-platform-sidebar/src/controllers/use-sidebar/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import type {
	onCreateArgs,
	onUpdateArgs,
	IFCTriggerRefType,
} from '@atlassian/jira-native-issue-table/src/common/ui/inline-field-config/types.tsx';
import { useIFCEntryPointContext } from '../context/index.tsx';

type useIFCSidebarProps = {
	projectId?: string | null;
	projectKey?: string;
	fieldId?: string;
};
type useIFCSidebarType = [
	boolean,
	{
		onCreate: (createArgs: onCreateArgs) => void;
		onUpdate: (updateArges: onUpdateArgs) => void;
		IFCTriggerRef: IFCTriggerRefType;
	},
];

const IFCTriggerRef = ({
	children,
}: {
	children: (props: { triggerRef: (element: HTMLElement | null) => void }) => React.ReactNode;
}) => {
	const { entryPointActions } = useIFCEntryPointContext();
	const triggerRef = useEntryPointButtonTrigger(entryPointActions);

	return children({ triggerRef });
};

export const useIFCSidebar = ({ projectId, projectKey }: useIFCSidebarProps): useIFCSidebarType => {
	const ifcContext = useIFCEntryPointContext();
	const [, { setStaticSidebarContent, closeSidebar }] = useSidebar();
	const isAdmin = useIsAdmin();
	const { data: project } = useProjectContext();

	const isIFCEnabled = Boolean(isAdmin && project?.simplified);

	const onUpdate = useCallback(
		({ fieldId, onFieldDeleted }: onUpdateArgs) => {
			if (projectId && projectKey) {
				setStaticSidebarContent?.(
					'ifc-sidebar-edit',
					<Box xcss={styles.sidebarContent}>
						<JiraEntryPointContainer
							entryPointReferenceSubject={ifcContext.entryPointReferenceSubject}
							id="jira-nin-inline-field-config-sidebar"
							fallback={
								<Flex>
									<Spinner size="medium" />
								</Flex>
							}
							runtimeProps={{
								projectId,
								projectKey,
								onClose: closeSidebar,
								fieldId,
								mode: 'edit',
								onFieldDeleted,
							}}
						/>
					</Box>,
				);
			}
		},
		[
			closeSidebar,
			ifcContext.entryPointReferenceSubject,
			projectId,
			projectKey,
			setStaticSidebarContent,
		],
	);

	const onCreate = useCallback(
		(createArgs?: onCreateArgs) => {
			if (projectId && projectKey) {
				setStaticSidebarContent?.(
					'ifc-sidebar-create',
					<Box xcss={styles.sidebarContent}>
						<JiraEntryPointContainer
							entryPointReferenceSubject={ifcContext.entryPointReferenceSubject}
							id="jira-nin-inline-field-config-sidebar"
							fallback={
								<Flex>
									<Spinner size="medium" />
								</Flex>
							}
							runtimeProps={{
								projectId,
								projectKey,
								onClose: closeSidebar,
								onStartFieldCreate: createArgs?.onStartFieldCreate,
								onFieldCreated: createArgs?.onFieldCreated,
								onFieldCreateError: createArgs?.onFieldCreateError,
								mode: 'create',
							}}
						/>
					</Box>,
				);
			}
		},
		[
			closeSidebar,
			ifcContext.entryPointReferenceSubject,
			projectId,
			projectKey,
			setStaticSidebarContent,
		],
	);

	return useMemo(
		() => [isIFCEnabled, { IFCTriggerRef, onCreate, onUpdate }],
		[isIFCEnabled, onCreate, onUpdate],
	);
};

const styles = cssMap({
	sidebarContent: {
		height: '100%',
		paddingTop: token('space.150'),
		paddingRight: token('space.150'),
		paddingBottom: token('space.150'),
		paddingLeft: token('space.150'),
	},
});
