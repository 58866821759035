import { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { fetchQuery, useRelayEnvironment, graphql } from 'react-relay';
import type { ExternalInfo } from '@atlaskit/jql-editor';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { createAttributes } from '@atlassian/jira-forge-ui-analytics/src/common/utils/create-attributes/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type {
	jqlSearchStatus_Query,
	jqlSearchStatus_Query$data,
} from '@atlassian/jira-relay/src/__generated__/jqlSearchStatus_Query.graphql';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import messages from './messages.tsx';

export type Status = NonNullable<jqlSearchStatus_Query$data['jira']>['issueSearchStatus'];

export const FETCH_JQL_SEARCH_STATUS_DELAY = 2000; // wait 2s before making the request
export const FETCH_JQL_SEARCH_STATUS_RETRY = 7000; // wait 7s before retrying after the previous request
export const MAX_FETCH_COUNT = 5; // Check the status no more than 5 times for the same JQL search

const statusToMessages = (status: Status, formatMessage: FormatMessage): ExternalInfo[] => {
	if (status == null || !('functions' in status) || !Array.isArray(status?.functions)) {
		return [];
	}

	const processing = status.functions.filter(
		(fn) => fn.app && fn.function && fn.status === 'PROCESSING',
	);
	const functions = Array.from(new Set(processing.map((fn) => fn.function)));
	const apps = Array.from(new Set(processing.map((fn) => fn.app)));

	switch (functions.length) {
		case 0:
			return [];

		case 1: {
			const message = formatMessage(messages.singleSlowForgeFunction, {
				functionName: functions[0],
				appName: apps[0],
			});
			return [{ type: 'info', message }];
		}

		default: {
			let message;
			switch (apps.length) {
				case 1: {
					message = formatMessage(messages.multipleSlowForgeFunctionsFromTheSameApp, {
						appName: apps[0],
					});
					break;
				}
				case 2: {
					message = formatMessage(messages.multipleSlowForgeFunctionsTwoApps, {
						appName1: apps[0],
						appName2: apps[1],
					});
					break;
				}
				default:
					message = formatMessage(messages.multipleSlowForgeFunctionsThreeApps, {
						appName1: apps[0],
						appName2: apps[1],
						appName3: apps[2],
					});
			}

			return [{ type: 'info', message }];
		}
	}
};

const analyticsMeta = {
	id: 'jqlSearchStatus',
	packageName: 'jiraIssueNavigator',
	teamName: 'empanada',
};

const EMPTY_EXTERNAL_INFO: ExternalInfo[] = [];

export const useJqlSearchStatus = ({
	query,
	isSearching,
}: {
	query: string;
	isSearching: boolean;
}): { messages: ExternalInfo[] } => {
	const { formatMessage } = useIntl();
	const [statusMessages, setStatusMessages] = useState<ExternalInfo[]>(EMPTY_EXTERNAL_INFO);
	const isSearchingRef = useRef<boolean>(isSearching);
	const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
	const fetchCountRef = useRef<number>(0);
	const environment = useRelayEnvironment();
	const cloudId = useCloudId();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireOperationalEvent = useCallback(
		(action: string, attributes: Record<string, unknown>) =>
			fireOperationalAnalytics(
				createAnalyticsEvent({}),
				`${analyticsMeta.packageName}.${analyticsMeta.id} ${action}`,
				attributes,
			),
		[createAnalyticsEvent],
	);

	const cancelPendingOperations = useCallback(() => {
		timeoutRef.current && clearTimeout(timeoutRef.current);
		timeoutRef.current = null;
		fetchCountRef.current = 0;
	}, []);

	const scheduleSearchStatusFetch = useCallback(
		(delay: number) => {
			const isRetry = delay === FETCH_JQL_SEARCH_STATUS_RETRY;
			const defaultAnalyticsAttributes = createAttributes({ isRetry });
			const timeout = setTimeout(() => {
				if (fetchCountRef.current >= MAX_FETCH_COUNT) {
					fireOperationalEvent('interrupted', {
						...defaultAnalyticsAttributes,
						reason: 'MAX_ATTEMPTS_REACHED',
					});
					return;
				}
				fireOperationalEvent('attempt', defaultAnalyticsAttributes);
				fetchCountRef.current += 1;
				fetchQuery<jqlSearchStatus_Query>(
					environment,
					graphql`
						query jqlSearchStatus_Query($cloudId: ID!, $jql: String!) {
							jira @optIn(to: "JiraIssueSearchStatus") {
								issueSearchStatus(cloudId: $cloudId, jql: $jql) {
									functions {
										status
										function
										app
									}
								}
							}
						}
					`,
					{ cloudId, jql: query },
					{ fetchPolicy: 'network-only' },
				)
					.toPromise()
					.then((data) => {
						let hasPendingFn = false;
						const status = data?.jira?.issueSearchStatus ?? null;
						if (isSearchingRef.current && timeoutRef.current === timeout) {
							const infos = statusToMessages(status, formatMessage);
							setStatusMessages(infos);
							if (infos.length) {
								hasPendingFn = true;
								scheduleSearchStatusFetch(FETCH_JQL_SEARCH_STATUS_RETRY);
							}
						}
						fireOperationalEvent('finished', {
							...defaultAnalyticsAttributes,
							hasPendingFn,
						});
					})
					.catch((error: Error) => {
						fireErrorAnalytics({
							meta: analyticsMeta,
							attributes: createAttributes({ error, isRetry }),
						});
						if (isSearchingRef.current && timeoutRef.current === timeout) {
							scheduleSearchStatusFetch(FETCH_JQL_SEARCH_STATUS_RETRY);
						}
					});
			}, delay);
			timeoutRef.current = timeout;
		},
		[cloudId, environment, fireOperationalEvent, formatMessage, query],
	);

	useEffect(() => {
		isSearchingRef.current = isSearching;
	}, [isSearching]);

	useEffect(() => {
		setStatusMessages(EMPTY_EXTERNAL_INFO);
		cancelPendingOperations();
		if (isSearching) {
			scheduleSearchStatusFetch(FETCH_JQL_SEARCH_STATUS_DELAY);
		}
	}, [isSearching, query, cancelPendingOperations, scheduleSearchStatusFetch]);

	return useMemo(() => ({ messages: statusMessages }), [statusMessages]);
};
