import React, {
	createContext,
	useCallback,
	useContext,
	useMemo,
	type PropsWithChildren,
} from 'react';
import noop from 'lodash/noop';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { ROW_HEIGHT } from '@atlassian/jira-native-issue-table/src/common/constants.tsx';
import type { IssueNavigatorIssueSearchRefetchQuery$variables as IssueSearchVariables } from '@atlassian/jira-relay/src/__generated__/IssueNavigatorIssueSearchRefetchQuery.graphql';
import { MAX_ISSUES_PER_PAGE } from '../../common/constants.tsx';
import type { View } from '../../common/types.tsx';

const INFINITE_SCROLL_DEFAULT_OPTIONS = {
	pageSize: MAX_ISSUES_PER_PAGE,
	threshold: 5 * ROW_HEIGHT,
};

const INFINITE_SCROLL_INTERACTION_NAME = 'issue-navigator.infinite-scroll';

/**
 * We manage infinite scroll related pagination props inside the issue search service as an intermediary solution while
 * detail view and list view APIs are tightly coupled. In future, we want each view mode to have its own independent
 * issue connection, in which case these pagination props should be moved downward to the respective view components.
 */
type ContextProps = {
	/**
	 * Callback invoked when the user reaches the bottom scroll threshold to trigger the next page of issues to load.
	 *
	 * Passing both this callback and onLoadPrevious will enable infinite scroll pagination.
	 */
	onLoadNext: (
		count: number,
		options?: {
			onComplete?: ((arg: Error | null) => void) | undefined;
			UNSTABLE_extraVariables?: Partial<IssueSearchVariables> | undefined;
		},
	) => void;
	/**
	 * Callback invoked when the user reaches the top scroll threshold to trigger the previous page of issues to load.
	 *
	 * Passing both this callback and onLoadNext will enable infinite scroll pagination.
	 */
	onLoadPrevious: (
		count: number,
		options?: {
			onComplete?: ((arg: Error | null) => void) | undefined;
			UNSTABLE_extraVariables?: Partial<IssueSearchVariables> | undefined;
		},
	) => void;

	/**
	 * Boolean flag to indicate if the next page is currently loading, used to control the loading UI hints.
	 */
	isLoadingNext: boolean;
	/**
	 * Boolean flag to indicate if the previous page is currently loading, used to control the loading UI hints.
	 */
	isLoadingPrevious: boolean;

	/**
	 * Boolean flag to indicate if there is a next page available to load, controls if the table will request another
	 * page once the bottom loading threshold has been reached.
	 */
	hasNext: boolean;
	/**
	 * Boolean flag to indicate if there is a next page available to load, controls if the table will request another
	 * page once the top loading threshold has been reached.
	 */
	hasPrevious: boolean;
};

interface InfiniteScrollProps {
	onLoadNext: () => void;
	onLoadPrevious: () => void;
	isLoadingNext: boolean;
	isLoadingPrevious: boolean;
	hasNext: boolean;
	hasPrevious: boolean;
	activationThreshold: number;
}

type Options = {
	pageSize?: number;
	threshold?: number;
};

const InfiniteScrollContext = createContext<ContextProps>({
	onLoadNext: noop,
	onLoadPrevious: noop,
	isLoadingNext: false,
	isLoadingPrevious: false,
	hasNext: false,
	hasPrevious: false,
});

export const InfiniteScrollProvider = ({
	onLoadNext,
	onLoadPrevious,
	isLoadingNext,
	isLoadingPrevious,
	hasNext,
	hasPrevious,
	children,
}: PropsWithChildren<ContextProps>) => {
	const value = useMemo(
		() => ({ onLoadNext, onLoadPrevious, isLoadingNext, isLoadingPrevious, hasNext, hasPrevious }),
		[onLoadNext, onLoadPrevious, isLoadingNext, isLoadingPrevious, hasNext, hasPrevious],
	);

	return <InfiniteScrollContext.Provider value={value}>{children}</InfiniteScrollContext.Provider>;
};

/**
 * A hook that makes it easier to consume infinite scroll props.
 *
 * @param fieldSetIds Field set IDs currently displayed for issue results.
 * @param options.pageSize Determines the page size to be returned from the backend. Defaults to `50`.
 * @param options.threshold How far, in pixels, from the bottom or top of the issue list the next/previous page of items should start loading. Defaults to `200`.
 * @returns A result object with a stable prop getter `getInfiniteScrollProps`
 */
export function useInfiniteScroll({
	fieldSetIds,
	view,
	groupBy,
	options,
	isHierarchyEnabled,
	isHideDoneEnabled,
	isIssueHierarchySupportEnabled,
}: {
	fieldSetIds: string[];
	view: View | null;
	groupBy?: string | null;
	options?: Options;
	isHierarchyEnabled?: boolean | null;
	isHideDoneEnabled?: boolean | null;
	isIssueHierarchySupportEnabled?: boolean;
}): InfiniteScrollProps {
	const { pageSize, threshold } = { ...INFINITE_SCROLL_DEFAULT_OPTIONS, ...options };
	const {
		onLoadNext: onLoadNextProp,
		onLoadPrevious: onLoadPreviousProp,
		...rest
	} = useContext(InfiniteScrollContext);

	// Extra static variables to ensure consistent field set results are returned during pagination.
	const staticPaginationVariables = useMemo(
		() => ({
			...(isIssueHierarchySupportEnabled && {
				viewConfigInput: {
					staticViewInput: {
						isHierarchyEnabled: isHierarchyEnabled || false,
						...(expVal('jira_list_hide_done_items', 'isEnabled', false)
							? { isHideDoneEnabled }
							: {}),
					},
				},
			}),
			fieldSetsInput: {
				fieldSetIds,
			},
			groupBy,
			isPaginating: true,
		}),
		[fieldSetIds, groupBy, isHideDoneEnabled, isHierarchyEnabled, isIssueHierarchySupportEnabled],
	);

	const onLoadNext = useCallback(() => {
		if (view && fg('add_nin_press_interactions')) {
			traceUFOPress(`${INFINITE_SCROLL_INTERACTION_NAME}.${view}`);
		}

		return onLoadNextProp(pageSize, {
			UNSTABLE_extraVariables: staticPaginationVariables,
		});
	}, [onLoadNextProp, pageSize, staticPaginationVariables, view]);

	const onLoadPrevious = useCallback(() => {
		if (view && fg('add_nin_press_interactions')) {
			traceUFOPress(`${INFINITE_SCROLL_INTERACTION_NAME}.${view}`);
		}

		onLoadPreviousProp(pageSize, {
			UNSTABLE_extraVariables: staticPaginationVariables,
		});
	}, [onLoadPreviousProp, pageSize, staticPaginationVariables, view]);

	const infiniteScrollProps = useMemo<InfiniteScrollProps>(
		() => ({
			onLoadNext,
			onLoadPrevious,
			activationThreshold: threshold,
			...rest,
		}),
		[onLoadNext, onLoadPrevious, threshold, rest],
	);

	return infiniteScrollProps;
}
