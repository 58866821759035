/* eslint-disable @atlassian/relay/graphql-naming */
import React, { type PropsWithChildren, useCallback, useMemo } from 'react';
import { graphql, type RefetchFn, useFragment, useRelayEnvironment } from 'react-relay';
import { useFlagsService, toFlagId, type FlagConfiguration } from '@atlassian/jira-flags';
import { isFilterId } from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import type { IssueNavigatorIssueSearchRefetchQuery } from '@atlassian/jira-relay/src/__generated__/IssueNavigatorIssueSearchRefetchQuery.graphql';
import type { issueSearch_issueNavigator$key as ViewFragment } from '@atlassian/jira-relay/src/__generated__/issueSearch_issueNavigator.graphql';
import type { issueSearch_issueNavigatorGrouping$key } from '@atlassian/jira-relay/src/__generated__/issueSearch_issueNavigatorGrouping.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { isRefactorNinToViewSchemaEnabled } from '@atlassian/jira-issue-navigator-rollout/src/is-refactor-nin-to-view-schema-enabled.tsx';
import { PACKAGE_NAME, GROUPED_LIST_EXPERIENCE_ID } from '../../common/constants.tsx';
import type { IssueNavigatorViewId, OverridableIssueTableProps } from '../../common/types.tsx';
import { parseIssueNavigatorViewIdOrDefault } from '../../common/utils/index.tsx';
import { useActiveJql } from '../../services/active-jql/index.tsx';
import { useExperienceAnalytics } from '../../services/experience-analytics/index.tsx';
import { useMainIssueNavigatorQueryShadowRequest } from '../../services/issue-search-shadow-request/index.tsx';
import { IssueSearchServiceContainer } from '../../services/issue-search/index.tsx';
import { useSelectedViewMutation } from '../../services/selected-view/index.tsx';
import messages from './messages.tsx';

type Props = PropsWithChildren<{
	/**
	 * Event emitted when page data has loaded and the key experience is interactive.
	 */
	onPageDataLoad?: (selectedView: IssueNavigatorViewId) => void;
	/**
	 * Fragment ref of the issue search view currently rendered.
	 */
	view: ViewFragment | null;
	/**
	 * Fragment ref of the view currently rendered.
	 */
	jiraView: issueSearch_issueNavigatorGrouping$key | null;
	/**
	 * Relay refetch function to perform a new issue search.
	 */
	refetch: RefetchFn<IssueNavigatorIssueSearchRefetchQuery>;
	/**
	 * Flag indicating whether issue hierarchy feature is enabled in the experience
	 */
	isIssueHierarchySupportEnabled: boolean | undefined;
	/**
	 * Props for shadow requests.
	 */
	issueTableProps?: OverridableIssueTableProps;
}>;

const searchErrorFlagId = toFlagId('ISSUE_NAVIGATOR_SEARCH_ERROR_FLAG');
const searchErrorFlag: FlagConfiguration = {
	id: searchErrorFlagId,
	type: 'error',
	title: messages.searchFailedTitle,
	description: messages.searchFailedDescription,
	messageId: `issue-navigator.ui.issue-search.searchErrorFlag.${searchErrorFlagId}`,
	messageType: 'transactional',
};

export const IssueSearch = ({
	children,
	onPageDataLoad,
	view,
	refetch,
	isIssueHierarchySupportEnabled,
	jiraView,
	issueTableProps,
}: Props) => {
	const { showFlag, dismissFlag } = useFlagsService();
	const environment = useRelayEnvironment();
	const cloudId = useCloudId();
	const { jql, filterId } = useActiveJql();
	const issueSearchInput = useMemo(
		() => (jql === undefined && isFilterId(filterId) ? { filterId } : { jql }),
		[filterId, jql],
	);
	const onSelectedViewMutation = useSelectedViewMutation();

	const issueSearchViewData = useFragment<ViewFragment>(
		graphql`
			fragment issueSearch_issueNavigator on JiraIssueSearchView
			@argumentDefinitions(
				isJscM2Enabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/jsc-m2-fe-changes.relayprovider"
				}
			) {
				viewConfigSettings(staticViewInput: $staticViewInput) @include(if: $isJscM2Enabled) {
					isHierarchyEnabled
					hideDone
				}
				viewId @required(action: THROW)
			}
		`,
		view,
	);

	const jiraViewData = useFragment<issueSearch_issueNavigatorGrouping$key>(
		graphql`
			fragment issueSearch_issueNavigatorGrouping on JiraView {
				... on JiraIssueSearchViewMetadata {
					viewId
				}
				... on JiraSpreadsheetView {
					viewSettings(
						groupBy: $groupBy
						staticViewInput: $staticViewInput
						issueSearchInput: $issueSearchInput
					) {
						isGroupingEnabled
						canEnableGrouping
						groupByConfig {
							groupByField {
								fieldId
							}
						}
						isHierarchyEnabled
						hideDone
					}
				}
			}
		`,
		jiraView,
	);

	let groupedByField: string | null = null;
	let isGroupingEnabled: boolean;
	let isHierarchyEnabled: boolean;
	let isHideDoneEnabled: boolean;
	let maybeViewId: string | null | undefined;

	if (isRefactorNinToViewSchemaEnabled()) {
		groupedByField = jiraViewData?.viewSettings?.groupByConfig?.groupByField?.fieldId ?? null;
		isGroupingEnabled =
			Boolean(issueTableProps?.isGroupingSupported) &&
			Boolean(jiraViewData?.viewSettings?.isGroupingEnabled) &&
			Boolean(jiraViewData?.viewSettings?.canEnableGrouping);
		isHierarchyEnabled = Boolean(
			isIssueHierarchySupportEnabled && jiraViewData?.viewSettings?.isHierarchyEnabled,
		);
		isHideDoneEnabled = Boolean(jiraViewData?.viewSettings?.hideDone);
		maybeViewId = jiraViewData?.viewId;
	} else {
		isGroupingEnabled = false;
		isHierarchyEnabled = Boolean(
			isIssueHierarchySupportEnabled && issueSearchViewData?.viewConfigSettings?.isHierarchyEnabled,
		);
		isHideDoneEnabled = Boolean(issueSearchViewData?.viewConfigSettings?.hideDone);
		maybeViewId = issueSearchViewData?.viewId;
	}

	const onRefetchStart = useCallback(() => {
		// Hide any search error flags currently visible
		dismissFlag(searchErrorFlagId);
	}, [dismissFlag]);

	const viewId = parseIssueNavigatorViewIdOrDefault(maybeViewId);
	const { onIssueSearchFail } = useExperienceAnalytics(onPageDataLoad, viewId, {
		...(isGroupingEnabled ? { experienceId: GROUPED_LIST_EXPERIENCE_ID } : {}),
	});
	const onRefetchError = useCallback(
		(error: Error, refetchView: IssueNavigatorViewId) => {
			onIssueSearchFail(`${PACKAGE_NAME}.issue-search-refetch`, error, refetchView);
			showFlag(searchErrorFlag);
		},
		[onIssueSearchFail, showFlag],
	);
	const projectContext = issueTableProps?.projectContext;

	useMainIssueNavigatorQueryShadowRequest(
		{
			first: 50,
			amountOfColumns: 500,
			fieldSetIds: [],
			shouldQueryFieldSetsById: false,
			cloudId,
			issueSearchInput,
			atlassianIntelligenceProductFeature: 'NATURAL_LANGUAGE_TO_JQL',
			projectKey: projectContext ?? '',
			shouldQueryProject: false,
			filterId: filterId ?? null,
			viewConfigInput: {
				viewInput: {
					namespace: 'ISSUE_NAVIGATOR',
				},
			},
			fieldSetsInput: {
				viewInput: {
					namespace: 'ISSUE_NAVIGATOR',
					filterId: filterId ?? null,
				},
			},
			shouldQueryHasChildren: true,
		},
		viewId,
	);

	return (
		<IssueSearchServiceContainer
			cloudId={cloudId}
			environment={environment}
			issueSearchInput={issueSearchInput}
			viewId={viewId}
			filterId={filterId}
			onPageDataLoad={onPageDataLoad}
			onRefetchStart={onRefetchStart}
			onRefetchError={onRefetchError}
			onSelectedViewMutation={onSelectedViewMutation}
			refetch={refetch}
			projectContext={projectContext}
			isHierarchyEnabled={isHierarchyEnabled}
			isHideDoneEnabled={isHideDoneEnabled}
			groupedByField={groupedByField}
			isIssueHierarchySupportEnabled={isIssueHierarchySupportEnabled}
		>
			{children}
		</IssueSearchServiceContainer>
	);
};
